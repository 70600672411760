import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Tabs } from '@independent-software/typeui/controls';
import { ResultsTable } from './tables/ResultsTable';

interface IProps {
  /** @ignore */
  className?: string;
}

const DataAreaBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Tabs underlined>
        <Tabs.Pane label="Results">
          <PaneSpacer>
            <ResultsTable/>
          </PaneSpacer>
        </Tabs.Pane>
        {/* <Tabs.Pane label="Campaigns">
          <PaneSpacer>
            <CampaignsTable/>
          </PaneSpacer>
        </Tabs.Pane> */}
      </Tabs>
    </div>
  )
}

const DataArea = styled(DataAreaBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${p => p.theme.background};
`

const PaneSpacer = styled.div`
  margin-top: 5px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export { DataArea }