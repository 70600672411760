import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Tray } from './Tray';
import { DrawerOptions } from './DrawerOptions';
import { DrawerHeader } from './DrawerHeader';
import { DrawerNodata } from './DrawerNodata';
import { DrawerButtons } from './DrawerButtons';
import { DrawerContent } from './DrawerContent';

// Drawer opening delay (ms)
const DRAWER_DELAY = 350;

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Is the drawer currently open? */
  open: boolean;
  /** Fired when drawer wants to closed. */
  onClose: () => void;
}

const DrawerBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
    <Shader onClick={props.onClose}/>
  </div>

const Shader = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  background: rgba(0,0,0,0.3);
`

const DrawerStyled = styled(DrawerBase)`
  position: absolute;
  z-index: 199;
  top: 0;
  bottom: 0;
  left: 75px;
  width: ${p => p.open ? 100 : 0}vw;
  transition: width ease-in-out ${DRAWER_DELAY}ms;
  border-left: solid 1px #333;
  overflow: hidden; 
`

const Drawer = (props: IProps) => <DrawerStyled {...props}/>

Drawer.Header = DrawerHeader;
Drawer.Options = DrawerOptions;
Drawer.Nodata = DrawerNodata;
Drawer.Tray = Tray;
Drawer.Buttons = DrawerButtons;
Drawer.Content = DrawerContent;

export { Drawer }
