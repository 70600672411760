import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { ListResponse, System } from "../models";

class SystemApi {
  static list(access_token: string, signal?: AbortSignal): Promise<ListResponse<System>> {
    const url = new Url("systems");
    return Api.Get<ListResponse<System>>(url.toString(), access_token, signal);
  }

  static get(id: number, access_token: string, signal?: AbortSignal): Promise<System> {
    return Api.Get<System>(`system/${id}`, access_token, signal);
  }

  static update(id: number, system: System, access_token: string, signal?: AbortSignal): Promise<System> {
    return Api.Put<System>(`system/${id}`, system, access_token, signal);
  }
}

export { SystemApi }
