import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme';

import { SVG } from '../svg';
import { useFilter } from '../contexts/filter/useFilter';

const TRANSITION_TIME = 300;

type TSplitterState = 'full' | 'open' | 'closed';

interface IProps {
  /** @ignore */
  className?: string;
  /** Content for left-hand panel of splitter. */
  left: React.ReactNode;
  /** Content for right-hand panel of splitter. */
  right: React.ReactNode;
  /** Splitter state (full, open, closed) */
  state: TSplitterState;
  /** Fired on splitter state change. */
  onState: (state: TSplitterState) => void;
}

const SplitterBase = (props: IProps) => {

  const filter = useFilter();

  const handleOpen = () => {
    if(props.state == 'closed') {
      props.onState('open');
    } else if(props.state == 'open') {
      props.onState('full');
    }
  }

  const handleClose = () => {
    props.onState('closed');
  }

  React.useEffect(() => {
    if(filter.systems.length == 0 || filter.parameter == null || filter.campaign == null) {
      props.onState('closed');
    }
  }, [filter.systems, filter.parameter, filter.campaign]);

  return (
    <div className={props.className}>
      <PaneLeft>
        {props.left}
      </PaneLeft>
      <PaneRight>
        {filter.systems.length != 0 && filter.parameter != null && filter.campaign != null && <Teaser onClick={handleOpen}>{props.state == 'closed' ? 'VIEW DATA' : 'EXPAND'}</Teaser>}
        <Closer onClick={handleClose}>
          <use xlinkHref={SVG.Icons.Close}></use>
        </Closer>
        {props.right}
      </PaneRight>
    </div>
  )
}

const Closer = styled.svg`
  position: absolute;
  z-index: 9999;
  right: 8px;
  top: 8px;
  fill: #eee;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    fill: #fff;
  }
`

const Teaser = styled.div`
  position: absolute;
  z-index: 9999;
  right: 100%;
  top: 80px;
  padding: 8px 12px 8px 16px;
  background: #111;
  color: white;
  white-space: nowrap;
  user-select: none;
  text-transform: uppercase;
  cursor: pointer;
  border-top-left-radius: ${p => p.theme.radius}px;
  border-bottom-left-radius: ${p => p.theme.radius}px;
  &:hover {
    background: #222;
  }
`

const PaneLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: width ease-in-out ${TRANSITION_TIME}ms;
`

const PaneRight = styled.div`
  position: absolute;
  height: 100%;
  transition: left ease-in-out ${TRANSITION_TIME}ms,
              width ease-in-out ${TRANSITION_TIME}ms;
  background: #fff;
  border-left: solid 1px #333;  
`

const Splitter = styled(SplitterBase)`
  flex: 1;
  position: relative;
  ${PaneLeft} {
    ${p => p.state == 'closed' && css`width: 100%;`}
    ${p => p.state == 'open'   && css`width: 50%;`}
    ${p => p.state == 'full'   && css`width: 50%;`}
  }
  ${PaneRight} {
    z-index: 100;
    ${p => p.state == 'closed' && css`left: 100%; width: 50%;`}
    ${p => p.state == 'open'   && css`left: 50%; width: 50%;`}
    ${p => p.state == 'full'   && css`left: 0%; width: 100%;`}
  }
`

export { Splitter, TSplitterState }
