import * as React from 'react';
import { System } from '../../../api/models';
import { Drawer } from '../../../ui/containers/panels/Drawer';
import { Fab, Loader, Property } from '../../../ui/core';
import { SVG } from '../../../svg';
import { StampedModel } from '../../../api/models/StampedModel';
import { Datum } from '@independent-software/typeui/formatters/Datum';

interface IProps {
  system: System;
  loading: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const ViewSystem = (props: IProps) => {
  const getCreator = (data: StampedModel) => {
    if(data.created_by == null && data.created_at == null) return "unknown";
    return (
      <>
        {data.created_by ? data.created_by.email : "unknown user"} @ {data.created_at ? <Datum.ShortDateTime value={data.created_at}/> : "unknown date"}
      </>
    );
  }

  const getUpdater = (data: StampedModel) => {
    if(data.updated_by == null && data.updated_at == null) return "unknown";
    return (
      <>
        {data.updated_by ? data.updated_by.email : "unknown user"} @ {data.updated_at ? <Datum.ShortDateTime value={data.updated_at}/> : "unknown date"}
      </>
    );
  }  

  return (
    <Drawer.Tray>
      {props.loading && <Loader/>}
      <Drawer.Header justify>
        {props.system.name}
      </Drawer.Header>
      <Drawer.Options stretch>
        <Property label="Name" value={props.system.name}/>
        <Property label="Stations" value={props.system.stations_count}/>
        <Property label="Campaigns" value={props.system.campaigns_count}/>
        <Property label="Created" value={getCreator(props.system)}/>
        <Property label="Updated" value={getUpdater(props.system)}/>
      </Drawer.Options>
      <Drawer.Buttons>
        <Fab path={SVG.Icons.Close} onClick={props.onClose}>Cancel</Fab>
        <Fab path={SVG.Icons.Edit} onClick={props.onEdit}>Edit</Fab>
      </Drawer.Buttons>
    </Drawer.Tray>
  )
}

export { ViewSystem }
