import * as React from 'react';
import styled, { css } from "@independent-software/typeui/styles/Theme";

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Add padding to content? */
  padded?: boolean;
  /** 
   * Minimum box height.
   * @default: 0
   */
  minHeight?: number;
}

const ContentBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>;

const ContentStyled = styled(ContentBase)`
  pointer-events: all;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(8px);
  &:nth-child(1) { // if no header
    border-top-left-radius: ${p => p.theme.radius}px;
    border-top-right-radius: ${p => p.theme.radius}px;
  }
  border-bottom-left-radius: ${p => p.theme.radius}px;
  border-bottom-right-radius: ${p => p.theme.radius}px;
  ${p => p.padded && css`padding: 10px 14px 10px 21px;`}
  ${p => p.minHeight && css`min-height: ${p.minHeight}px`}
  flex: 1;
  box-sizing: border-box;
  color: white;
  font-family: "Cousine", sans-serif;
  font-size: 14px;
`;

/**
 * InfoBox.Content fills up all vertical space it can get.
 */
const Content = (props: IProps) => <ContentStyled {...props}/>;

export { Content }
