import * as React from 'react';
import styled, { ThemeProvider } from '@independent-software/typeui/styles/Theme';

import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Password } from '@independent-software/typeui/modules/Password';

import { useTheme } from '../../theme/useTheme';
import { ISigninRequest } from '../../api/services/auth/ISigninRequest';

interface ISigninFormProps {
  /** Initial form data. */
  data: ISigninRequest;
  /** Called whenever form changes. */
  onChange: (data: ISigninRequest, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Are form controls disabled? */
  disabled?: boolean;
  dirty?: boolean;
  onSubmit: () => void;
}

const SigninForm = (props: ISigninFormProps) => {
  const theme = useTheme();
  const [data, setData] = React.useState<ISigninRequest>(props.data);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.key == 'Enter') props.onSubmit();
  }
  
  return (
    <div onKeyDown={handleKeyDown}>
      <ThemeProvider theme={{...theme, background: '#ffffff', fontColor: '#333333' }}>
        <Form data={data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
          <Column>
            <Form.Field 
              required={{message: "Email is required."}}
              name="email" 
              boxed
              label="Email"
              value={data.email}
              control={(<Input transparent disabled={props.disabled} type="text" placeholder="Email" fluid/>)}
            />
            <Form.Field 
              name="password" 
              value={data.password}
              label="Password"
              boxed
              control={(<Password transparent disabled={props.disabled} placeholder="Password" fluid/>)}
            />
          </Column>
        </Form>
      </ThemeProvider>
    </div>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export { SigninForm }
