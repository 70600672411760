import * as React from 'react';
import { useMap } from 'react-map-gl';

import { PositionBox, IPosition } from './PositionBox';

// localStorage key for saving and restoring positions.
const LOCALSTORAGE_KEY = 'positions';

interface IProps {
  /** 
   * Maximum number of positions that may be saved
   *  @default 3
   */
  maxPositions?: number;
}

const PositionsManager = (props: IProps) => {
  const map = useMap();

  // Retrieve positions from localStorage. Returns [] if no localStorage.
  const loadPositionsFromLocalStorage = (): IPosition[] => {
    const json = localStorage.getItem(LOCALSTORAGE_KEY);
    if(json == null) return [];
    return JSON.parse(json) as IPosition[];
  }

  // Save positions to localStorage.
  const savePositionsToLocalStorage = (positions: IPosition[]) => {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(positions));
  }

  // Positions are restored from localStorage, if any were saved.
  const [positions, setPositions] = React.useState<IPosition[]>(loadPositionsFromLocalStorage());

  React.useEffect(() => {
    // Add a context menu (right-click) handler to the map.
    map.current.on('contextmenu', (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const maxPositions = props.maxPositions ?? 3;
      // Add click coordinates to positions list.
      setPositions(positions => {
        // Allow no more than 3 saved positions.
        if(positions.length >= maxPositions) return positions;
        // Add new position to positions list.
        const position = { lng: ev.lngLat.lng, lat: ev.lngLat.lat, zoom: map.current.getZoom() };
        const newPositions = [position, ...positions];
        // Store new positions list in localStorage.
        savePositionsToLocalStorage(newPositions);
        return newPositions;
      });
    });
  }, []);

  const handleMovePosition = (idx: number, x: number, y: number) => {
    const {lng, lat} = map.current.unproject([x,y]);
    const position = { lng, lat, zoom: map.current.getZoom() };
    const head = positions.slice(0, idx);
    const tail = positions.slice(idx + 1);
    const newPositions = [...head, position, ...tail];
    setPositions(newPositions);
    savePositionsToLocalStorage(positions);
  }

  const handleDeletePosition = (idx: number) => {
    positions.splice(idx, 1);
    setPositions([...positions]);
    savePositionsToLocalStorage(positions);
  }

  const handleFocus = (idx: number) => {
    const position = positions[idx];
    map.current.easeTo({
      center: [position.lng, position.lat],
      zoom: position.zoom
    });
  }
  
  return positions.map((position: IPosition, idx: number) => 
    <PositionBox 
      key={idx} 
      coord={position} 
      onMove={(x, y) => handleMovePosition(idx, x, y)} 
      onDelete={() => handleDeletePosition(idx)}
      onFocus={() => handleFocus(idx)}
    />
  );
}

export { PositionsManager }
