import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { SVG } from '../../../../../svg';

interface IProps {
  /** @ignore */
  className?: string;
  /** Fired when button is clicked. */
  onClick: () => void;
}

const RemoveColumnButtonBase = (props: IProps) => {
  const handleClick = (e?: React.MouseEvent) => {
    // Do not allow click to reach TableHeaderCell.
    e.stopPropagation();
    props.onClick();
  }

  return (
    <div className={props.className} title="Remove column" onClick={handleClick}>
      <svg viewBox="0 0 100 100">
        <use xlinkHref={SVG.TypeUI.Times}></use>
      </svg>
    </div>
  );
}

const RemoveColumnButton = styled(RemoveColumnButtonBase)`
  display:     flex;
  box-sizing:  border-box;
  width:       17px;
  height:      17px;
  padding:     3px;
  background-color: #b44e4e;
  transition: background-color ease-in-out 150ms;
  border-radius: 50%;

  cursor: pointer;
  &:hover {
    background-color: #d25b5b;
  }
  svg {
    fill: #fff;
  }
`

export { RemoveColumnButton }
