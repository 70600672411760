import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const MenuBarBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const MenuBar = styled(MenuBarBase)`
  position: relative;
  width: 75px;
  display: flex;
  z-index: 200;
  align-items: start;
  flex-direction: column;
  background: linear-gradient(#457298, #2C4253);
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  justify-content: space-between;
  padding-left: 12px;
`

export { MenuBar }
