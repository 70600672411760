import { Icons } from "./icons"
import { Logos } from "./logo"
import { Menu } from "./menu"

enum TypeUI {
  AreaChart = "/spritemap.svg#area-chart",
  Arrow = "/spritemap.svg#arrow",
  At = "/spritemap.svg#at",
  Barchart = "/spritemap.svg#bar-chart",
  Bars = "/spritemap.svg#bars",
  Bullhorn = "/spritemap.svg#bullhorn",
  CaretDown = "/spritemap.svg#caret-down",
  CaretUp = "/spritemap.svg#caret-up",
  Check = "/spritemap.svg#check",
  Chevron = "/spritemap.svg#chevron",
  ChevronDouble = "/spritemap.svg#chevron-double",
  Circle = "/spritemap.svg#circle",
  CircleNotch = "/spritemap.svg#circle-notch",
  CloudUpload = "/spritemap.svg#cloud-upload",
  Code = "/spritemap.svg#code",
  Download = "/spritemap.svg#download",
  Edit = "/spritemap.svg#edit",
  Envelope = "/spritemap.svg#envelope",
  exchange = "/spritemap.svg#exchange",
  Eye = "/spritemap.svg#eye",
  EyeSlash = "/spritemap.svg#eye-slash",
  Filecsv = "/spritemap.svg#file-csv",
  FileDownload = "/spritemap.svg#file-download",
  FileExcel = "/spritemap.svg#file-excel",
  FileGeojson = "/spritemap.svg#file-geojson",
  FileOds = "/spritemap.svg#file-ods",
  Filter = "/spritemap.svg#filter",
  HeartBeat = "/spritemap.svg#heart-beat",
  IdCard = "/spritemap.svg#id-card",
  Layers = "/spritemap.svg#layers",
  LineChart = "/spritemap.svg#line-chart",
  Link = "/spritemap.svg#link",
  Lock = "/spritemap.svg#lock",
  Minus = "/spritemap.svg#minus",
  Plus = "/spritemap.svg#plus",
  Save = "/spritemap.svg#save",
  Search = "/spritemap.svg#search",
  Ttable = "/spritemap.svg#table",
  Th = "/spritemap.svg#th",
  Times = "/spritemap.svg#times",
  Tools = "/spritemap.svg#tools",
  Trash = "/spritemap.svg#trash",
  Unlock = "/spritemap.svg#unlock",
  ZoomExtents = "/spritemap.svg#zoom-extents",
  ZoomMinus = "/spritemap.svg#zoom-minus",
  ZoomPlus  = "/spritemap.svg#zoom-plus"
}

export const SVG = { Icons, Logos, Menu, TypeUI };
export type SVG = typeof SVG;
