import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Icon, Input } from '@independent-software/typeui/controls';

import { Parameter } from '../../../api/models';
import { Panel } from './Panel';
import { CurrentParameterCard } from './CurrentParameterCard';
import { Drawer } from '../../../ui/containers/panels/Drawer';
import { useFilter } from '../../../contexts/filter/useFilter';
import { Counter, Highlight, Loader } from '../../../ui/core';
import { Table } from '../../../ui/containers';
import { ParameterInfoBox } from '../../infoboxes/ParameterInfoBox/ParameterInfoBox';

interface IProps {
  /** @ignore */
  className?: string;
  open?: boolean;
}

const ParametersTrayBase = (props: IProps) => {
  const filter = useFilter();
  const [trayIndex, setTrayIndex] = React.useState(0);
  const [q, setQ] = React.useState("");
  const [parameter, setParameter] = React.useState(null);

  const handleSelectParameter = (parameter: Parameter) => {
    setParameter(parameter);
  }

  const handleActivateParameter = (parameter: Parameter) => {
    filter.setParameter(parameter);
  }

  const handleClearParameter = () => {
    filter.setParameter(null);
  }

  const handleChangeQ = (value: string) => setQ(value);

  const handleSelectTray = (idx: number) => {
    setTrayIndex(idx);
  }

  // Format parameter name according to parameter availability for the currently
  // selected systems.
  const formatParameterName = (parameter: Parameter) => {
    if(!parameter.name) return "(no name)";
    const name = <Highlight value={parameter.name} q={q}/>;
    if(!parameter.data) return <Unavailable>{name}</Unavailable>;
    return name;
  }

  const filteredParameters = filter.parameters ? filter.parameters
    .filter(parameter => parameter.name.toLowerCase().includes((q ?? "").toLowerCase()))
    : [];
  const availableParameters = filteredParameters.filter(p => p.data);
  const unAvailableParameters = filteredParameters.filter(p => !p.data);
  const tableParameters = [...availableParameters, ...unAvailableParameters];

  if(!props.open) return null;

  return (
    <Drawer.Tray className={props.className}>
      {(filter.parameters == null || filter.parametercategories == null) && <Loader/>}
      {filter.parameters != null && filter.parametercategories != null && <>
        <Drawer.Options white>
          <Input fluid clearable type="text" placeholder="Search" value={q} onChange={handleChangeQ}>
            <Icon name="search"/>
          </Input>
        </Drawer.Options>
        <Drawer.Header justify>PARAMETERS</Drawer.Header>
        <Body>
          {filter.parametercategories.map((cat, idx) => {
            const params = tableParameters
              .filter(p => p.parametercategories.map(c => c.id).includes(cat.id))
            if(params.length == 0) return null;
            return <Panel key={idx} title={cat.name} count={params.filter(p => p.data).length} open={trayIndex == idx} onToggle={() => handleSelectTray(idx)}>
              <Table 
                animated noHeader hover data={params} active={parameter} 
                singular="parameter" plural="parameters" noCounter={true} onClick={p => handleSelectParameter(p)} 
                noData={<div style={{padding: '0 16px'}}><Table.NoData/></div>}
                infoBox={<ParameterInfoBox parameter={parameter} onSelect={handleActivateParameter}/>}
              >
                <Table.Column name="Name" width={1}>{(item: Parameter) => formatParameterName(item)}</Table.Column>
              </Table>              
            </Panel>
          })}
        </Body>
      </>}
    </Drawer.Tray>
  );
}

const ParametersTray = styled(ParametersTrayBase)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Unavailable = styled.span`
  text-decoration: line-through;
  color: #aaa;
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export { ParametersTray }
