import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { Filter } from '../contexts/filter/Filter';
import { Screen } from '../ui-domain/Screen';
import { useAuth } from '../contexts/auth/useAuth';

const ProtectedRoute = () => {
  const auth = useAuth();

  if (auth.access_token == null) {
    return <Navigate to="/signin" replace/>
  }

  return (
    <Filter>
      <Screen/>
    </Filter>
  );
}

export { ProtectedRoute }
