import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Datum } from '@independent-software/typeui/formatters/Datum';

import { ListResponse, Result } from '../../api/models';
import { ResultApi } from '../../api/services/ResultApi';
import { Table } from '../../ui/containers/lists/Table/Table';
import { useFilter } from '../../contexts/filter/useFilter';
import { Loader } from '../../ui/core';

const ResultsTable = () => {
  const filter = useFilter();
  const sectionController = React.useRef<AbortController>(null);
  const [sort, setSort] = React.useState("samples.date");
  const [reverse, setReverse] = React.useState(true); 
  const [items, setItems] = React.useState<Result[]>(null);
  const [loading, setLoading] = React.useState(true);
  const [sectionLoading, setSectionLoading] = React.useState(false);

  const loadResults = (offset: number, length: number): Promise<ListResponse<Result>> => {
    return ResultApi.list(null, filter.systems, filter.parameter, filter.campaign, offset, length, sort, reverse)
  }

  // Initial load:
  React.useEffect(() => {
    if(!filter.parameter || !filter.campaign) {
      setItems([]);
      return;
    }
    setLoading(true);
    loadResults(0, 50)
    .then(res => {
      setItems([...res.data, ...Array(res.count - res.data.length).fill(null)]);
    })
    .catch((error) => {
    })
    .finally(() => setLoading(false));
  }, [filter.parameter, filter.systems, filter.campaign, sort, reverse]);

  // 
  // Returns true if list section [offset, offset+length} contains any empty
  // items.
  //
  const sectionNeedsLoading = (offset: number, length: number) => {
    return !(items.slice(offset, offset + length).indexOf(null) == -1);
  }

  const loadSection = (offset: number, length: number) => {
    // Setup an abortcontroller.
    if(sectionController.current) sectionController.current.abort();
    sectionController.current = new AbortController();
    setTimeout(() => setSectionLoading(true), 100); // (aborting overwrites "true" state).
    loadResults(offset, length)
    .then(res => {
      for(let i = 0; i < res.data.length; i++) {
        if(items[i + offset] != null) continue;
        items[i + offset] = res.data[i];
      }
      setItems([...items]);
    }).catch((err) => {
      // Cancellation is quiet.
      //if(!sectionController.current.signal.aborted) throw err;
    }).finally(() => {
      setSectionLoading(false);
    });
  }

  const handleSort = (sort: string, reverse: boolean) => {
    setSort(sort);
    setReverse(reverse);
  }

  const handleScroll = (offset: number, length: number) => {
    if(!sectionNeedsLoading(offset, length)) return;
    loadSection(offset, length);
  }  

  if(!filter.parameter) {
    return (
      <Table.NoData>Please select a <b>parameter</b> in order to show results.</Table.NoData>
    );
  }

  if(filter.systems.length == 0) {
    return (
      <Table.NoData>Please select a <b>system</b> in order to show results.</Table.NoData>
    );    
  }

  if(!filter.campaign) {
    return (
      <Table.NoData>Please select a <b>campaign</b> in order to show results.</Table.NoData>
    );
  }


  if(items === null) {
    return <Loader/>
  }

  const getFilter = () => {
    return (<>
      <div style={{margin: "16px"}}>
        (Filter not yet implemented.)
      </div>
    </>);
  }

  return (
    <>
      {loading && <Loader/>}
      <Table 
        striped data={items} loading={sectionLoading} sort={sort} reverse={reverse} filter={getFilter()} onSort={handleSort}
        singular="result" plural="results" onScroll={handleScroll} noCounter={loading} 
        noData={loading ? null : <Table.NoData/>}
      >
        <Table.Column name="Station" sort="stations.name">{(item: Result) => item.sample.station.name}</Table.Column>
        <Table.Column name="Date" sort="samples.date">{(item: Result) => <Datum.ShortDate value={item.sample.date}/>}</Table.Column>
        <Table.Column name="Value" sort="value" align="right">{(item: Result) => <><Number value={item.value} decimals={2}/> <Unit>{filter.parameter.unit}</Unit></>}</Table.Column>
      </Table>
    </>
  );
}

const Unit = styled.span`
  font-size: 80%;
`

export { ResultsTable }
