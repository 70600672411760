import * as React from 'react';
import styled, { css } from '@independent-software/typeui/styles/Theme';

import { Header } from './elements/Header';
import { Content } from './elements/Content';
import { Footer } from './elements/Footer';
import { Menu } from './elements/Menu';
import { Row } from './elements/Row';
import { Button } from './elements/Button';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** 
   * Box width.
   * @default 320
   */
  width?: number;
  /**
   * It set, InfoBox will fill screen vertically.
   */
  fill?: boolean;
}

/**
 * An `InfoBox` is a general wrapper for different Infobox implementations.
 * It sets the standard InfoBox width. It provides various elements to compose
 * an InfoBox: `Header`, `Content` and `Footer`.
 */
const InfoBoxBase = (props: IProps) =>
  <div className={props.className}>
    {/* Everything but footer(s) go inside a border: */}
    <Border>
      {React.Children.toArray(props.children).filter((c: any) => c.props.__type !== 'footer')}
    </Border>
    {/* The footer(s) follow, outside the border: */}
    {React.Children.toArray(props.children).filter((c: any) => c.props.__type === 'footer')}
  </div>

const Border = styled.div`
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
  border-radius: 12px;  
  display: flex;
  flex-direction: column;
  flex: 1;
` 

const InfoBoxStyled = styled(InfoBoxBase)`
  position: relative;
  width: ${p => p.width ?? 320}px;
  user-select: none;
  
  ${p => p.fill && css`height: calc(100vh - 150px);`}
  display: flex;
  flex-direction: column;
  pointer-events: none;
`

const InfoBox = (props: IProps) => <InfoBoxStyled {...props}/>
  
InfoBox.Header = Header;
InfoBox.Content = Content;
InfoBox.Footer = Footer;
InfoBox.Menu = Menu;
InfoBox.Row = Row;
InfoBox.Button = Button;

export { InfoBox }
