import * as React from 'react';
import styled, { css, keyframes } from '@independent-software/typeui/styles/Theme';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { ITableColumnProps, TableColumn } from './TableColumn';
import { lighten } from '@independent-software/typeui/helper/lighten';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Colors } from '../../../../theme/Colors';
import { NoData } from './NoData';

// Height of a single table row: 
const TABLE_ROW_HEIGHT = 48; // px

interface IProps {
  /** @ignore */
  className?: string;
  /** 
   * TableColumn specifications. These are a set of `TableColumn` children,
   * each of which set a column name, width, sort key and sort direction, and
   * cell content alignment. The content of a TableColumn is a function which
   * is given a data row and returns formatted cell content.
   */
  children?: React.ReactElement<ITableColumnProps> | React.ReactElement<ITableColumnProps>[];
  /** 
   * Array of data to show in the table 
   */
  data: any[];
  /** 
   * Active record, if any. This record will be highlighted. If an `infoBox`
   * is set, it will be shown by the active record, but not shown when there
   * is no active record.
   */
  active?: any;
  /** 
   * Current sort column, if any. This column will be highlighted. 
   */
  sort?: string;
  /**
   * Sort reverse. 
   */
  reverse?: boolean;
  /** 
   * Optional custom sort value retrieval. 
   */
  onSortValue?: (item: any, key: string) => any;
  /** 
   * If set, horizontal and vertical grid lines are drawn between cells. 
   */
  grid?: boolean;
  /** 
   * If set, rows will be striped. 
   */
  striped?: boolean;
  /** 
   * If set, rows will have a hover effect. 
   */
  hover?: boolean;
  /** 
   * If set, show a loading animation. The animation is infinite in duration. 
   */
  loading?: boolean;
  /**
   *  If set, no record counter is shown. 
   */
  noCounter?: boolean;
  /** 
   * Singular unit used in record counter, e.g. "user". Also see `plural`. 
   */
  singular?: React.ReactNode;
  /** 
   * Plural unit used in record counter, e.g. "users". Also see `singular`. 
   */
  plural?: React.ReactNode;
  /** 
   * Should an animation be played as rows come in? 
   */
  animated?: boolean;
  /**
   * Fired when sorting is requested.
   */
  onSort?: (sort: string, reverse: boolean) => void;
  /**
   * Fired when a row is clicked.
   * @param item Clicked item
   */
  onClick?: (item: any) => void;
  /**
   * Fired when a row is double-clicked.
   * @param item Clicked item
   */
  onDoubleClick?: (item: any) => void;
  /** 
   * Fired when a row is hovered. 
   * @param item Hovered item
   */
  onHover?: (item: any) => void;
  /** 
   * If present, a checks column is added. This callback is fired when a 
   * cell checked state is toggled. 
   * @param data The table's dataset is returned, with a `checked` key on each 
   * element. 
   */
  onCheck?: (data: any[]) => void;
  /** 
   * If present, a column deletion option is added. 
   * This callback is fired when the delete option is called.  
   * @param index 0-based column index.
   */
  onDeleteColumn?: (index: number) => void;
  /** 
   * If present, an add column option is added. 
   * This callback is fired when the add column button is clicked.
   */
  onAddColumn?: () => void;
  /**
   * If present, a filter column is added.
   * The filter contents are shown in a panel.
   */
  filter?: React.ReactNode;
  /** 
   * If a component is provided, then it is shown next to the active item, 
   * and will scroll with the table (but not leave the screen).  If the active
   * item is null, then the infoBox is not shown.
   */
  infoBox?: React.ReactNode;
  /**
   * Fired when table scrolling finishes (after a debounce).
   * @param offset Index of first row in view (0-based)
   * @param length Number of row in view
   */
  onScroll?: (offset: number, length: number) => void;
  /**
   * Content for expandable rows. Expansion is done by setting a property
   * `expanded` on a row. If `expansion` is not provided, then expanding rows
   * have no effect.
   */
  expansion?: React.FunctionComponent<any>;
  /** 
   * Component to show when there is no data (data is `null` or data is `[]`).
   * The component is placed under the header, and the component is responsable
   * for any styling.
   */
  noData?: React.ReactNode;
  /** 
   * If set, table is headerless. 
   */
  noHeader?: boolean;
}

const TableBase = (props: IProps) => {

  // Needed for infoBox placement:
  const scrollPos = React.useRef<number>(0);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLDivElement>(null);
  const boxRef = React.useRef<HTMLDivElement>(null);

  // After item selection or filter, we must update the infobox's position.
  React.useEffect(() => {
    setInfoBoxPosition();
  }, [props.active, props.data]);

  // Sort data:
  const handleSort = (newSort: string, defaultReverse: boolean) => {
    // Make sure table scrolls back to top.
    tableRef.current.scrollTo(0, 0);
    if(props.sort === newSort) {
      props.onSort(newSort, !props.reverse);
    } else {
      props.onSort(newSort, defaultReverse ?? false);
    }
  }
  
  // If a row check is clicked:
  // * An `index` of null means all checks must be inverted
  // * A number index is the 0-based row index of the row that must be inverted.
  const handleCheck = (index: number) => {
    if(index === null) {
      props.onCheck(props.data.map(x => { return { ...x, checked: !x.checked } }));
    } else {
      (props.data[index] as any).checked = !(props.data[index] as any).checked;
      props.onCheck(props.data);
    }
  }

  const setInfoBoxPosition = (offset?: number) => {
    if(wrapperRef.current == null || boxRef.current == null || props.active == null) return;
    if(!offset) {
      offset = scrollPos.current;
    } else {
      scrollPos.current = offset;
    }
    const pos = props.data.indexOf(props.active);
    const ROWHEIGHT = TABLE_ROW_HEIGHT;
    const HEADERHEIGHT = props.noHeader ? 0 : TABLE_ROW_HEIGHT;
    const top = Math.min(wrapperRef.current.clientHeight - boxRef.current.clientHeight - 16, Math.max(HEADERHEIGHT, -offset + HEADERHEIGHT + pos * ROWHEIGHT)) + "px";
    boxRef.current.style.top = top;
  }
  React.useEffect(() => { setInfoBoxPosition() }, [props.active]);

  const doScrollDebounced = () => {
    // Don't to any calculations if table not yet initialized, or if there
    // is no listener.
    if(tableRef.current == null) return;
    if(!props.onScroll) return;

    // Find all expansion boxes whose tops are above the currently visible
    // table section:
    const expansions = Array.from(tableRef.current.querySelectorAll('tr.expansion'))
    .filter((expansion: Element) => {
      const expansionTop = expansion.getBoundingClientRect().y; 
      const tableTop = tableRef.current.getBoundingClientRect().y + TABLE_ROW_HEIGHT; // add table header
      return tableTop >= expansionTop;
    });
    // Sum up the total height of these boxes:
    const expansionsHeight = expansions.reduce((a, current) => a + current.clientHeight, 0);
    
    // The first row to be loaded is, without expansions, the table's scrollTop
    // position divided by the height of each row. This gets reduced by the height
    // of the expansions currently open.
    let offset = Math.max(0, Math.floor((tableRef.current.scrollTop - expansionsHeight) / TABLE_ROW_HEIGHT));
    let length = Math.ceil(tableRef.current.clientHeight / TABLE_ROW_HEIGHT);
    // Add 50 rows to both top and bottom to reduce number of required loads.
    offset = Math.max(offset - 50, 0);
    length += 100;
    props.onScroll(offset, length);
  }
  const handleScrollDebounced = AwesomeDebouncePromise(doScrollDebounced, 200);

  const handleScroll = () => {
    if(tableRef.current == null) return;
    setInfoBoxPosition(tableRef.current.scrollTop);
    handleScrollDebounced();
  }

  return (
    <div className={props.className} ref={wrapperRef}>
      <InfoBoxHolder ref={boxRef} style={{display: props.active ? 'block' : 'none'}}> 
        {/* Only show Infobox if there is a selected item, there is an infobox, 
            and the selected item isn't filtered out. */}
        {(props.active && props.infoBox && props.data.includes(props.active)) ? props.infoBox : null}
      </InfoBoxHolder>
      <LoadingBar/>
      {(props.noData && (props.data == null || props.data.length == 0)) && <NoDataHolder>
        {props.noData}
      </NoDataHolder>}
      <TableInner onScroll={handleScroll} ref={tableRef}>
      
        <table>

          {!props.noHeader && <TableHeader 
            sort={props.sort} 
            reverse={props.reverse} 
            onClick={props.onSort ? handleSort : null} 
            onCheck={props.onCheck ? handleCheck : null} 
            onDeleteColumn={props.onDeleteColumn} 
            onAddColumn={props.onAddColumn}
            filter={props.filter}
          >
            {props.children}
          </TableHeader>}

          <TableBody 
            data={props.data} 
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            onHover={props.onHover}
            onCheck={props.onCheck ? handleCheck : null} 
            hasAddColumn={!!props.onAddColumn}
            hasFilter={!!props.filter}
            animated={props.animated}
            active={props.active}
            expansion={props.expansion}
          >
            {props.children}
          </TableBody>

        </table>

      </TableInner>
      {!props.noCounter && <Count>
        <Number value={props.data.length} decimals={0}/> {props.data.length == 1 ? (props.singular ?? 'record') : (props.plural ?? 'records')}
      </Count>}      
    </div>
  );
}

const NoDataHolder = styled.div`
  position: absolute;
  top: ${TABLE_ROW_HEIGHT}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const InfoBoxHolder = styled.div`
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  z-index: 1;
  // Holder itself cannot catch pointer events; children should reset 
  // pointer-events.
  pointer-events: none;
`

const Count = styled.div`
  position: absolute;
  font-style: italic;
  z-index: 1;
  left: 0;
  bottom: 0;
  background: #333;
  color: #fff;
  padding: 5px 8px;
  user-select: none;
  pointer-events: none;
`

const lineAnimation = keyframes`
  0%   { width: 0%;   }
  50%  { width: 100%; }
  100% { width: 0%;   }
`;

const TableInner = styled.div``;
const LoadingBar = styled.div``;

const emptyAnimation = keyframes`
  0%   { opacity: 0.5; }
  50%  { opacity: 1.0; }
  100% { opacity: 0.5; }
`

const TableStyled = styled(TableBase)`
  position:    relative;
  width:       100%;
  height:      100%;
  font-size:   14px;

  ${LoadingBar} {
    position: absolute;
    z-index: 2;
    top: 47px;
    left: 0;
    width: 0;
    height: 2px;
    background: ${p => lighten(0.2, p.theme.primaryColor)};
    ${p => p.loading && css`animation: ${lineAnimation} 20s linear infinite;`}
  }
  
  ${TableInner} {
    position:  absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    /* Table is full-width: */
    table {
      width:     100%;
      position:  relative;
      border-collapse: collapse; 
      table-layout: fixed;
      margin-bottom: 88px;
    }
    
    tr {
      position: relative;
      background-color: ${p => p.theme.background};
    }

    /* Row striping and hovering (for non-empty rows): */
    tr:not(.empty) {
      ${p => p.striped && css`
        &:nth-child(2n+2) {
          background-color: ${p => lighten(0.05, p.theme.background)};
        }
      `}
      // empty rows do not have hover.
      ${p => p.hover && css`
        &:hover {
          background-color: ${p => lighten(0.1, p.theme.background)};
        }
      `}
    }

    // Empty rows can be very tall (representing many rows). They simulate
    // striping using a linear gradient.
    tr.empty {
      ${p => p.striped && css`
        &:nth-child(2n+2) {
          background: repeating-linear-gradient(${lighten(0.05, p.theme.background)} 0px, ${lighten(0.05, p.theme.background)} ${TABLE_ROW_HEIGHT}px, ${p.theme.background} ${TABLE_ROW_HEIGHT}px, ${p.theme.background} ${2*TABLE_ROW_HEIGHT}px);
        }
        &:nth-child(2n+1) {
          background: repeating-linear-gradient(${p.theme.background} 0px, ${p.theme.background} ${TABLE_ROW_HEIGHT}px, ${lighten(0.05, p.theme.background)} ${TABLE_ROW_HEIGHT}px, ${lighten(0.05, p.theme.background)} ${2*TABLE_ROW_HEIGHT}px);
        }
      `}
      // A light animation shows that empty rows are being loaded.
      animation: ${emptyAnimation} 4s infinite;
    }

    tr.active {
      background: ${Colors.PRESSED};
    }

    th, td {
      height:         ${TABLE_ROW_HEIGHT}px;
      vertical-align: middle;
      box-sizing:     border-box;
      white-space:    nowrap;
      text-align:     left;
      user-select:    none;
    }

    th {
      /* Position */
      position:  sticky;
      top:       0;
      z-index:   1;

      /* Dimensions */
      padding:     0 16px 0 16px;

      /* Appearance */
      background:  ${Colors.PRESSED};
      color:       #fff;
    }

    td {
      text-overflow:  ellipsis;
      overflow:       hidden;
      padding:        0 16px 0 16px;
      color:        #fff;
    }
    td:last-child {
      padding-right: 32px;
    }

    tr.expansion td {
      padding: 0;
    }
    
    // Only 2nd row and onwards has a top border.
    ${p => p.grid && css`
      tr:not(:first-child) td {
        border-top: solid 1px red;
      }
    `}

    // Every cell has a left border, except
    // - first cell
    // - 2nd cell, if checkboxes are present
    // - last n cells, if filter and/or addcolumn are present.
    ${p => p.grid && css` 
      td:not(:first-child) {
        border-left: solid 1px #eee;
      }
      ${!!p.onCheck && css`
        td:nth-child(2) { border-left: none; }
      `}
      ${(!!p.onAddColumn || !!p.filter) && css`
        td:nth-last-child(-n+1) { border-left: none; }
      `}
      ${!!p.onAddColumn && !!p.filter && css`
        td:nth-last-child(-n+2) { border-left: none; }
      `}
    `}
  }
`

const Table = (props: IProps) => <TableStyled {...props}/>

Table.Column = TableColumn;
Table.NoData = NoData;

Table.sort = (items: any[], sort: string, reverse: boolean, onSortValue?: (item: any, key: string) => any) => {
  let newItems = [...items];
  newItems.sort((a,b) => {
    if(a == null || b == null) return 0;
    let vA:any = (onSortValue ? onSortValue(a, sort) : (a as any)[sort]) ?? "";
    let vB:any = (onSortValue ? onSortValue(b, sort) : (b as any)[sort]) ?? "";
    // Trim strings, because leading spaces will throw sort off.
    if(typeof vA === 'string') vA = vA.trim();
    if(typeof vB === 'string') vB = vB.trim();
    if(vA.localeCompare) return vA.localeCompare(vB);
    return vA - vB;
  });
  if(reverse) newItems.reverse();
  return newItems;
}

export { Table, TABLE_ROW_HEIGHT }
