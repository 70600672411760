import * as React from 'react';
import styled, { keyframes } from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** Is corresponding column currently sorted? */
  active?: boolean;
  /** If set, sort button is reversed (Z-A). */
  reverse?: boolean;
}

const SortButtonBase = (props: IProps) => 
  <div className={props.className}>
    <svg viewBox="0 0 100 100">
      <path className="bottom" d="M9.5,77.9h81c4.3,0,8.1,3.3,8.1,7.2v6.1c0,3.9-3.8,7.2-8.1,7.2h-81c-4.3,0-8.1-3.3-8.1-7.2
        v-6.1C1.4,81.2,5.2,77.9,9.5,77.9z"/>
      <path className="middle" d="M9.5,39.5h52.9c4.3,0,8.1,3.3,8.1,7.2v6.1c0,3.9-3.8,7.2-8.1,7.2H9.5c-4.3,0-8.1-3.3-8.1-7.2
        v-6.1C1.4,42.9,5.2,39.5,9.5,39.5z"/>
      <path className="top" d="M10,1.1h24.8c4.9,0,8.6,3.3,8.6,7.2v6.1c0,3.9-3.8,7.2-8.6,7.2H10c-4.9,0-8.6-3.3-8.6-7.2V8.4
        C1.4,3.9,5.2,1.1,10,1.1z"/>
    </svg>
  </div>

const SortIconAnimation = keyframes`
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

/**
 * The SortButton displays a caret that rotates and lights up depending on
 * its active/reverse props.
 */
const SortButton = styled(SortButtonBase)`
  // Position
  position:    absolute;
  display:     flex; // Needed for SVG to be centered in div.
  top:         18px;
  left:        16px;
  width:       12px;
  height:      12px;

  // Appearance
  transition: opacity ease-in-out 150ms,
              transform ease-in-out 150ms;
  transform: scaleY(${p => p.reverse ? -1 : 1});
  opacity: ${p => p.active ? 1 : 0};

  svg {
    transition: fill ease-in-out 150ms;
    fill: #fff;
    path.top, path.middle, path.bottom {
      transform-origin: 0 0;
      animation-name: ${SortIconAnimation};
      // Animation duration is set by parent TableHeaderCell.
      animation-duration: 0;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    path.middle {
      animation-delay: 0.25s;
    }
    path.bottom {
      animation-delay: 0.5s;
    }     
  }
`

export { SortButton }
