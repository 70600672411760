import * as React from 'react';
import { Dialog } from '@independent-software/typeui/controls';
import { ConfirmContext } from './ConfirmContext';

interface IProps {
  /** @ignore */
  children?: React.ReactNode;
}

/**
 * The Confirm context wraps all its content, adding a confirmation Dialog.
 * Keeping the Dialog outside of all app content avoids it being contaminated
 * with styling, or being out-z-indexed. 
 * 
 * The confirmation dialog can be opened with:
 * const confirm = useConfirm();
 * confirm.start(() => console.log("Confirmed"), "Please confirm", "Are you sure?")
 */
const Confirm = (props: IProps) => {
  const [active, setActive] = React.useState(false);
  const [title, setTitle] = React.useState<string>(null);
  const [children, setChildren] = React.useState<React.ReactNode>(null);
  const onConfirm = React.useRef<() => void>(null);

  const start = (_onConfirm: () => void, title?: string, children?: React.ReactNode) => {
    onConfirm.current = _onConfirm;
    setActive(true);
    setTitle(title ?? "Confirm deletion");
    setChildren(children ?? "Are you sure you wish to delete this item?");
  }

  return (
    <ConfirmContext.Provider value={{
      start: start      
    }}>
      {props.children}
      <Dialog.Confirm open={active} onClose={() => setActive(false)} onConfirm={onConfirm.current} title={title}>
        {children}
      </Dialog.Confirm>
    </ConfirmContext.Provider>
  );  
}

export { Confirm }
