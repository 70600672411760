import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { SVG } from '../../../../svg';

const TRAY_WIDTH = 350;

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /**
   * Can tray be expanded?
   */
  expandable?: boolean;
  /**
   * Is tray currently expanded?
   */
  expanded?: boolean;
  /**
   * Fired when tray is expanded or contracted.
   */
  onToggle?: () => void;
}

const TrayBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.expandable && <ExpandButton onClick={props.onToggle}>
        <use xlinkHref={SVG.Icons.Arrow}></use>
      </ExpandButton>}
      <LogoText>
        <use xlinkHref={SVG.Logos.LogoOnlyText}></use>
      </LogoText>
      {props.children}
    </div>
  );
}

//
// This is the "AQUA" text that appears at the top of each drawer.
// 
const LogoText = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 40px;
  margin-top: 25px;
  margin-left: 16px;
`

const ExpandButton = styled.svg`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: transform ease-in-out 250ms;
  fill: #eee;
  &:hover {
    fill: #fff;
  }
`

const Tray = styled(TrayBase)`
  position: absolute;
  z-index: 1;
  left: 0;
  width: ${p => p.expanded ? `calc(100vw - 358px - 75px)` : `${TRAY_WIDTH}px`};
  transition: width ease-in-out 250ms;
  top: 0;
  bottom: 0;
  background-color: ${p => p.theme.background};
  box-sizing: border-box;
  padding-top: 80px;  

  display: flex;
  flex-direction: column;  

  ${ExpandButton} {
    transform: rotate(${p => p.expanded ? 180 : 0}deg);
  }
`

export { Tray }