import * as React from 'react';
import { Form, Input } from '@independent-software/typeui/controls';

import { System } from '../../../api/models';

interface IProps {
  system: System;
  dirty?: boolean;
  onChange: (company: System, forceUpdate: boolean) => void;
  onValidate: (valid: boolean) => void;
}

const SystemForm = (props: IProps) => {
  return (
    <Form data={props.system} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
      <Form.Field 
        required={{message: 'Required field'}}
        maxLength={{length: 255, message: "Max length is 255"}}
        label="NAME"
        name="name"
        boxed
        value={props.system.name}
        control={<Input transparent fluid placeholder="Fish Co." type="text"/>}            
      />
    </Form>
  );
}

export { SystemForm }
