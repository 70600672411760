import * as React from 'react';
import { Campaign, Parameter, Parametercategory, System } from '../../api/models';

interface IFilter {
  systems: System[]; setSystems: (systems: System[]) => void;
  hasSystem: (system: System) => boolean;
  toggleSystem: (system: System) => void;
  deselectSystem: (system: System) => void;
  parameter: Parameter; setParameter: (parameter: Parameter) => void;
  parameters: Parameter[]; setParameters: (parameters: Parameter[]) => void;
  parametercategories: Parametercategory[]; setParametercategories: (parametercategories: Parametercategory[]) => void;
  campaign: Campaign; setCampaign: (campaign: Campaign) => void;
  mapstyle: string; setMapstyle: (key: string) => void;
}

const FilterContext = React.createContext<IFilter>({
  systems: [], setSystems: () => {},
  hasSystem: () => false,
  toggleSystem: () => {},
  deselectSystem: () => {},
  parameter: null, setParameter: () => {},
  parameters: [], setParameters: () => {},
  parametercategories: [], setParametercategories: () => {},
  campaign: null, setCampaign: () => {},
  mapstyle: null, setMapstyle: () => {}
});

export { FilterContext, IFilter }
