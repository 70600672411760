import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const PublicRouteBase = (props: IProps) => 
  <div className={props.className}>
    <Page>
      <Content>
        {props.children}
      </Content>
    </Page>
  </div>

const Content = styled.div`
  position: absolute;
  width: 400px;
  max-width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const Page = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: conic-gradient(from 0deg at 0 0, #071119 0deg, #071119 90deg, #243961 180deg, #243961 -1deg);
  background-blend-mode: multiply;
`

const PublicRoute = styled(PublicRouteBase)`
  position:    absolute;
  left:        0;
  top:         0;
  width:       100vw;
  height:      100vh;
  overflow:    hidden;  
`

export { PublicRoute }
