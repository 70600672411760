import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IProps {
  /** @ignore */
  className?: string;
  /** Scale min value */
  min: number;
  /** Scale max value */
  max: number;
}

const IdwScaleBase = (props: IProps) => 
  <div className={props.className}>
    <Surface/>
    <Labels>
      <Label><Number value={props.min} decimals={2}/></Label>
      <Label><Number value={props.max} decimals={2}/></Label>
    </Labels>
  </div>

const Surface = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to right, #3186bc 0%, #99d392 16.6%, #e3f397 33.3%, #fcffbb 50%, #fcde8b 66.6%, #f88857 83.3%, #d33c4f);
  border: solid 2px #333;
  border-radius: 4px;
`

const Label = styled.div`
  font-family: "Roboto", sans-serif;
  color: white;
  text-shadow: 0px 0px 2px #333;  
  background: #333;
  padding: 0px 6px;
  border-radius: 4px;
`

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const IdwScale = styled(IdwScaleBase)`
  position: absolute;
  left: 200px;
  bottom: 32px;
  right: 32px;
`

export { IdwScale }
