import * as React from 'react';
import styled, { css } from '@independent-software/typeui/styles/Theme';

import { Colors } from '../../../../../theme/Colors';
import { SVG } from '../../../../../svg';

interface IProps { 
  /** @ignore */
  className?: string;
  /** Is menu currently open? */
  open: boolean;
  /** Fired when menu is toggled. */
  onToggle: () => void;

  value: any;
  formatter: (v: any) => React.ReactNode;
}

const MenuSelectorBase = (props: IProps) => {

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle();
  }

  return (
    <div className={props.className} onClick={handleClick}>
      <SelectorValue>
        {props.formatter(props.value)}
      </SelectorValue>
      <Caret>
        <use xlinkHref={SVG.TypeUI.CaretDown}></use>
      </Caret>
    </div>
  );
}

const SelectorValue = styled.div`
  // Selector text must not overflow box.
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;  
`

const Caret = styled.svg`
  width: 16px;
  height: 16px;
  fill: ${Colors.MODERATELY_HIGH};
  transition: transform ease-in-out ${p => p.theme.transition.duration}s;
`

const MenuSelector = styled(MenuSelectorBase)`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
  height: 24px;
  ${p => p.open && css`
    background: #333;
  `}
  &:hover {
    background: #333;
  }
  ${Caret} {
    transform: rotate(${p => p.open ? -180 : 0}deg);
  }
`

export { MenuSelector }
