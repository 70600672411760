import * as React from 'react';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { ITableColumnProps } from './TableColumn';
import styled, { keyframes } from '@independent-software/typeui/styles/Theme';
import { TableBand } from './TableBand';
import { TABLE_ROW_HEIGHT } from './Table';

const ANIM_DELAY = 50;

interface IProps {
  /** Array of TableColumns */
  children?: React.ReactElement<ITableColumnProps> | React.ReactElement<ITableColumnProps>[];
  /** Table rows */
  data: any[];
  /** Active record, if any */
  active?: any;
  /** Fired when a row is clicked. */
  onClick?: (item: any) => void;
  /** Fired when a row is double-clicked. */
  onDoubleClick?: (item: any) => void;  
  /** Fired when a row is hovered. */
  onHover?: (item: any) => void;
  /** 
   * Is there a Check column in the header? This is the function that will
   * be called when the checkbox is interacted with.
   */
  onCheck?: (index: number) => void;
  /** Is there an Add Column option in the header? */
  hasAddColumn?: boolean;
  /** Is there a Filter button in the header? */
  hasFilter?: boolean;
  /** Should an animation be played as rows come in? */
  animated?: boolean;
  /** 
   * Content for expandable rows. This is a function component that will be 
   * used through React.createElement.
   */
  expansion?: React.FunctionComponent<any>;
}

const TableBody = (props: IProps) => {

  // Prevent click on checkbox from reaching row.
  const captureClick = (e?: React.MouseEvent) => {
    e.stopPropagation();
  }

  const renderCells = (row: any, index: number) => <>
    {/* Check column */}
    {!!props.onCheck === true && <td>
      <div onClick={captureClick}>
        <Checkbox type="check" checked={row.checked === true} onChange={() => props.onCheck(index)}/>
      </div>
    </td>}
    {/* Execute TableColumn's rendering function for each cell. */}
    {React.Children.map(props.children, (child, index) => {
      // Some table columns may be turned off:
      if(child == null) return null;
      return (
        <td 
          key={index} 
          style={{
            textAlign: child.props.align === 'right' ? 'right' : 'left',
          }}
        >
          {child.props.children(row)}
          {child.props.band && <TableBand color={child.props.band(row)}/>}
        </td>
      );
    })}
    {/* Add column */}
    {props.hasAddColumn === true && <td></td>}
    {/* Filter column */}
    {props.hasFilter === true && <td></td>}
  </>

  const renderRow = (row: any, index: number) => 
    <Row 
      key={index} 
      className={row === props.active ? "active" : null} 
      onMouseEnter={props.onHover ? () => props.onHover(row) : null} 
      onClick={props.onClick ? () => props.onClick(row) : null} 
      onDoubleClick={props.onDoubleClick ? () => props.onDoubleClick(row) : null}
      style={{
        cursor: props.onClick ? 'pointer' : 'auto', 
        opacity: props.animated ? 0 : 1,
        animationDelay: props.animated ? `${Math.min(index * ANIM_DELAY, 30 * ANIM_DELAY)}ms` : '0ms',
        animationDuration: props.animated ? '1000ms' : '0ms'
      }}
    >
      {renderCells(row, index)}
    </Row>

  //
  // Return the number of cells per row, which includes checkboxes, addColumn,
  // and filter.
  //
  const getCellCount = (): number => {
    return React.Children.toArray(props.children).filter(child => child != null).length
      + (props.onCheck ? 1 : 0) 
      + (props.hasAddColumn ? 1 : 0) 
      + (props.hasFilter ? 1 : 0);
  }

  const renderExpansion = (row: any, index: number) =>
    <tr className="expansion" key={`${index}_expanded`}>
      <td colSpan={getCellCount()}>
        {React.createElement(props.expansion, { item: row })}
      </td>
    </tr>

  //
  // Render a single tall <tr> that represents <numRows> empty rows.
  // 
  const renderEmpty = (numRows: number, index: number) =>
    <tr className="empty" key={index}>
      <td colSpan={getCellCount()} style={{height: `${numRows*TABLE_ROW_HEIGHT}px`}}></td>
    </tr>

  //
  // This renders all the rows. However, for sequences of empty rows, a single
  // tall element is used, so as not to render hundreds or thousands of empty
  // <tr><td/></tr> for the empties. 
  //
  const renderBody = (): React.ReactNode => {
    const elems: React.ReactNode[] = [];
    let emptyCount = 0;
    let lastNonEmptyIndex = 0;
    props.data.forEach((row, index) => {
      if(row == null) {
        emptyCount++;
      }
      else {
        if(emptyCount > 0) {
          elems.push(renderEmpty(emptyCount, lastNonEmptyIndex + 1));
          emptyCount = 0;
        }
        elems.push(renderRow(row, index));
        lastNonEmptyIndex = index;
        // Expanded rows get an extra row below them (with its key suffixed).
        // Only available is `props.expansion` is provided.
        if(row != null && props.expansion && row.expanded) {
          elems.push(renderExpansion(row, index));
        }
      }
    });
    if(emptyCount > 0) elems.push(renderEmpty(emptyCount, lastNonEmptyIndex + 1));
    return elems;
  }

  return (
    <tbody>
      {renderBody()}
    </tbody>
  );
}

const Animation = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`  

const Row = styled.tr`
  animation-name: ${Animation};
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`

export { TableBody }
