import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Key } from '@independent-software/typeui/controls/Key';
import { Mouse } from '@independent-software/typeui/controls/Mouse';

interface IProps {
  /** @ignore */
  className?: string;
}

const CompassHintBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Key>CTRL</Key> + <Mouse left/>
      Adjust bearing and pitch
    </div>
  );
}

const CompassHint = styled(CompassHintBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export { CompassHint }
