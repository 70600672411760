class Colors {
  static LOW = "#76B72D";
  static MODERATELY_LOW = "#33ACE3";
  static MODERATE = "#E9DB64";
  static MODERATELY_HIGH = "#F6A44E";
  static HIGH = "#E94A52";

  static DARK = "#3C698A";
  static BACKGROUND = "#2C4253";
  static PRESSED = "#273C44";
}

export { Colors }
