import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Label, LabelStyled } from '@independent-software/typeui/controls';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Label size */
  size: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';
}

const CounterBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Label color="#DDE0E7" size={props.size}>
        {props.children}
      </Label>
    </div>
  );
}

const Counter = styled(CounterBase)`
  ${LabelStyled} {
    color: #408DaE;
  }
`
export { Counter }
