import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Icon } from '@independent-software/typeui/controls';

import { Campaign } from '../../../api/models';
import { Datum } from '@independent-software/typeui/formatters/Datum';

interface IProps {
  /** @ignore */
  className?: string;
  /** Parameter for card */
  campaign: Campaign;
  /** Fired when parameter must be removed. */
  onRemove: () => void;
}

const CurrentCampaignCardBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Name title={props.campaign.name}>
        {props.campaign.name.toLocaleUpperCase()} <Datum.ShortDate value={props.campaign.date}/>
      </Name>
      <Icon name="times" title="Deselect campaign" onClick={props.onRemove}/>
    </div>
  );
}

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`

const CurrentCampaignCard = styled(CurrentCampaignCardBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  white-space: nowrap;
  gap: 8px;
`

export { CurrentCampaignCard }
