import * as React from 'react';
import { useMap } from 'react-map-gl';

interface IProps {
  /** @ignore */
  children?: React.ReactNode;  

  /** 
   * Dockable's latitude.
   * @default 0
   */
  latitude?: number;
  /**
   * Dockable's longitude.
   * @default 0
   */
  longitude?: number;

  /**
   * Dockable's x-offset from lat,lng position, while floating.
   * @default 0
   */
  offsetX?: number;
  /**
   * Dockable's y-offset from lat,lng position, while floating.
   * @default 0
   */
  offsetY?: number;
  swappable?: boolean;
  zIndex?: number;

  /** 
   * Force docking; don't allow Dockable to float. 
   */
  alwaysDocked?: boolean;
}

const Dockable = (props: IProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const map = useMap();  
  const myWidth = containerRef.current ? containerRef.current.clientWidth : 0;
  const myHeight = containerRef.current ? containerRef.current.clientHeight : 0;
  const mapWidth = map.current.getContainer().clientWidth;
  const mapHeight = map.current.getContainer().clientHeight;
  const {x, y} = map.current.project([props.longitude ?? 0, props.latitude ?? 0]);

  const offsetX = props.offsetX ?? 0;
  const offsetY = props.offsetY ?? 0;
  
  const offscreen = x + offsetX < 0 
                 || y + offsetY < 0 
                 || x + offsetX + myWidth + (props.swappable ? -myWidth - offsetX * 2 : 0) > mapWidth
                 || y + offsetY + myHeight > mapHeight;
  const rightHalf = x > mapWidth / 2;
  const bottomHalf = y > mapHeight / 2; // Future use for vertical swappable.

  // A local style object is used rather than styled-components, because the 
  // style changes constantly and would produce too many styled-components
  // classes.
  const style: React.CSSProperties = {
    position: offscreen ? 'relative' : 'absolute',
    color: 'white',
    zIndex: 1,
    pointerEvents: 'none'
    // transition: 'margin ease-in-out 200ms'
  };
  if(props.zIndex) {
    style.zIndex = props.zIndex;
  }
  if(!offscreen && !props.alwaysDocked) {
    style.marginLeft = props.swappable ? (rightHalf ? -myWidth - offsetX * 2 : 0) + "px" : 0;
    style.left = `${x + offsetX}px`;
    style.top = `${y + offsetY}px`;    
  }

  return (
    // Classname 'offscreen' is added so children can know the content they're 
    // referring to is offscreen.
    <div style={style} className={offscreen ? 'offscreen' : ''} ref={containerRef}>
      {props.children}
    </div>
  )  
}

export { Dockable }
