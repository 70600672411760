import * as React from 'react';
import { StyleReset, StyleBase, Theme } from '@independent-software/typeui/styles';
import { ThemeProvider } from 'styled-components';
import { IThemeInterface } from '@independent-software/typeui/styles/Theme';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';
import { Filter } from './contexts/filter/Filter';
import { Screen } from './ui-domain/Screen';
import { StyleExtra } from './theme/StyleExtra';
import { Confirm } from './contexts/confirm/Confirm';
import { Router } from './Router';
import { Auth } from './contexts/auth/Auth';
import { Xhr } from './contexts/xhr/Xhr';

const MyTheme: IThemeInterface = {
  ...Theme,
  background:      '#406A8C', //'#305A7C'
  normalColor:     "#aaaaaa",
  primaryColor:    "#2185d0",
  secondaryColor:  "#1b1c1d",
  positiveColor:   "#76B72D",
  negativeColor:   "#db2828",
  infoColor:       { color: "rgb(39, 111, 134)", background: "rgb(248, 255, 255)", border: "rgb(169, 213, 222)" },
  warningColor:    { color: "rgb(87, 58, 8)",    background: "rgb(255, 250, 243)", border: "rgb(201, 186, 155)" },
  successColor:    { color: "rgb(44, 102, 45)",  background: "rgb(252, 255, 245)", border: "rgb(163, 194, 147)" },
  errorColor:      { color: "#ffffff",  background: "#5c3030", border: "#c18787" }, 
  fontURL:         "https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i&subset=latin",
  fontName:        "Roboto",
  fontSize:        16,
  fontColor:       "#ffffff",
  fontLineHeight:  24,
  radius:          10,
  darken:          0.05,
}
const Main = () => {
  return (
    <>
      <StyleReset/>
      <StyleBase/>
      <StyleExtra/>
      <ThemeProvider theme={MyTheme}>
        <ToastContainer maxToasts={5} horizontalAlign='right' verticalAlign='top' horizontalOffset={20} verticalOffset={65}/>
        <Auth>
          <Xhr>
            <Confirm>
              <Router/>
            </Confirm>
          </Xhr>
        </Auth>
      </ThemeProvider>
    </>
  );
}

export { Main }
