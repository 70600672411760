import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** Band color. */
  color: string;
}

const TableBandBase = (props: IProps) => <div className={props.className}></div>;

/**
 * A TableBand is a small border on the left-hand side of a table cell.
 */
const TableBand = styled(TableBandBase)`
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  bottom: 0;
  background: ${p => p.color};
`

export { TableBand }
