import * as React from 'react';
import { Map as GLMap, MapboxMap, Source, Layer, ViewStateChangeEvent, MapLayerMouseEvent, MapboxGeoJSONFeature } from 'react-map-gl';
import * as turf from '@turf/helpers';

import { App } from '../App';
import { MapControls } from './MapControls';
import { Measurement, Station, System } from '../api/models';
import { FeatureCollection } from 'geojson';
import { HeatmapLayer } from '@deck.gl/aggregation-layers/typed';
import { DeckGLOverlay } from './DeckGLOverlay';
import { VisualizationFillLayer } from './layers/VisualizationFillLayer';
import { idwStrategy } from './visualizationstrategies/idwStrategy';
import { VisualizationSymbolLayer } from './layers/VisualizationSymbolLayer';
import { DateSlider } from '../ui/map/DateSlider/DateSlider';
import { StationApi } from '../api/services/StationApi';
import { ResultApi } from '../api/services/ResultApi';
import { SystemMarkers } from './SystemMarkers';
import { Area } from '../ui/map/Area/Area';
import { ResultGrid } from '../ui/map/ResultGrid/ResultGrid';
import { TriangulationLayer } from './layers/TriangulationLayer';
import { IdwLayer } from './layers/IdwLayer';
import { ClippedMap } from './ClippedMap';
import { ChipList } from '../ui/map/Chip/ChipList';
import { Chip } from '../ui/map/Chip/Chip';
import { Colors } from '../theme/Colors';
import { Dock, Dockable } from '../ui/map/Dockable';
import { SystemInfoBox } from '../ui-domain/infoboxes/SystemInfoBox';
import { StationInfoBox } from '../ui-domain/infoboxes/StationInfoBox';
import { Datum } from '@independent-software/typeui/formatters/Datum';
import { useFilter } from '../contexts/filter/useFilter';
import { PositionsManager } from '../ui/map/PositionsManager';
import { SystemBoundaries } from './SystemBoundaries';
import { StationMarkers } from './StationMarkers';
import { useAuth } from '../contexts/auth/useAuth';
import { ParameterInfoBox } from '../ui-domain/infoboxes/ParameterInfoBox/ParameterInfoBox';

interface IProps {
  // Location initially shown on map. Can be null.
  lat?: number;
  lng?: number;
  // Initial zoom level.
  zoom?: number;  
}

const strategy = idwStrategy;

const Map = (props: IProps) => {
  const auth = useAuth();
  const map = React.useRef<MapboxMap>(null);
  const filter = useFilter();
  const [stations, setStations] = React.useState<Station[]>([]);
  const [station, setStation] = React.useState<Station>(null);
  const [interactiveLayerIds, setInteractiveLayerIds] = React.useState([]);
  const [hoverStation, setHoverStation] = React.useState<Station>(null);
  const [hoverCluster, setHoverCluster] = React.useState<number>(null);
  const [features, setFeatures] = React.useState<FeatureCollection>(null);
  const [visualization, setVisualization] = React.useState(null);

  React.useEffect(() => {
    // Listen for document-wide events when Map mounts.
    document.addEventListener('zoomToSystem', handleZoomToSystem);
    document.addEventListener('zoomToStation', handleZoomToStation);
    return () => {
      // Clean up document-wide zoomToSystem event when Map unmounts.
      document.removeEventListener('zoomToSystem', handleZoomToSystem);
      document.removeEventListener('zoomToStation', handleZoomToStation);      
    }
  }, [])

  const handleZoomToSystem = (e: CustomEvent) => {
    const system: System = e.detail.system;
    map.current.fitBounds([[ system.minlng, system.minlat], [system.maxlng, system.maxlat]]);
  }

  const handleZoomToStation = (e: CustomEvent) => {
    const station: Station = e.detail.station;
    map.current.easeTo({ 
      center: [ station.longitude, station.latitude ],
      zoom: 12 
    });
  }

  React.useEffect(() => {
    const controller = new AbortController();
    StationApi.list(auth.access_token, { systems: filter.systems }, controller.signal)
    .then(res => {
      setStations(res.data);
      const featureCollection = turf.featureCollection(
        res.data
          .filter(s => s.latitude != null && s.longitude != null)
          .map(s => turf.point([s.longitude, s.latitude], { id: s.id }))
      );    
      setFeatures(featureCollection);  
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });

    return () => { if(controller) controller.abort(); }
  }, [filter.systems]);

  /* React.useEffect(() => {
    setResults([]);
    if(filter.parameter == null) return;
    if(filter.systems.length == 0) return;
    if(filter.campaign == null) return;

    const controller = new AbortController();

    ResultApi.map(null, filter.campaign, filter.parameter, controller.signal)
    .then(res => {
      setResults(res);
      const timeStart = Date.now();
      const vis = strategy(res);
      const timeEnd = Date.now();
      console.log("Total visualization time:", timeEnd-timeStart, "ms");
      setVisualization(vis);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });    

    return () => { if(controller) controller.abort(); }
  }, [filter.parameter, filter.systems, filter.campaign]); */

  // If lng/lat prop a valid location?
  const isLocationValid = (): boolean => {
    if(isNaN(parseFloat(props.lat as any)) || isNaN(parseFloat(props.lng as any))) {
       return false;
    }
    return true;
  }  

  const [viewState, setViewState] = React.useState({
    longitude: isLocationValid() ? props.lng : -5.85,
    latitude: isLocationValid() ? props.lat : 54.66,
    zoom: props.zoom ?? 11,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 }    
  });  

  const handleLoad = (e: mapboxgl.MapboxEvent) => {
    map.current = e.target;
    setInteractiveLayerIds([ 'markers-circles' ]);
  }  

  const handleMove = (e: ViewStateChangeEvent) => {
    setViewState(e.viewState);
  }

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    // If source isn't yet loaded, MapGL throws an error:
    if(!map.current || !map.current.loaded) return;

    // Turn hovering off:
    setHoverStation(null);
    setHoverCluster(null);
    if(e.features.length == 0) return;

    // Find associated IDs:
    const featureID = e.features[0].properties.id;
    const clusterID = e.features[0].properties.cluster_id;

    if(clusterID) { 
      setHoverCluster(clusterID);
      return;
    }

    if(featureID) {
      const station = stations.find(e => e.id === featureID);
      setHoverStation(station);
      map.current.setFeatureState({
        source: 'stations',
        id: station.id
      }, { hover: true });
    }
  }  

  const handleMouseLeave = (e: MapLayerMouseEvent) => {
    setHoverStation(null);
    setHoverCluster(null);
    map.current.setFeatureState({
      source: 'stations',
      id: hoverStation.id
    }, { hover: false });
  }  

  const handleClickMarker = (feature: MapboxGeoJSONFeature) => {
    const selectedStation = stations.find(s => s.id == feature.properties.id);
    setStation(selectedStation);
  }

  const handleClick = (e: MapLayerMouseEvent) => {
    // Deselect current station:
    setStation(null);
    // Check for clicks on clusters-circles layer:
    /* let features = map.current.queryRenderedFeatures(e.point, { layers: ['clusters-circles-hover']});
    if(features.length > 0) handleClickCluster(features[0]); */
    // Check for clicks on markers-circles layer:
    let features = map.current.queryRenderedFeatures(e.point, { layers: ['markers-circles']});
    if(features.length > 0) handleClickMarker(features[0]);
  }

  const layers = [
    /* new TextLayer({
      data: results,
      getPosition: (r: IValue) => [r.lng, r.lat, 250],
      getText: (r: IValue) => Math.round(r.v).toString(),
      getSize: 20
    }), */
    /* new HeatmapLayer({ 
      id: 'heatmap-layer',
      beforeId: "clusters-circles",
      data: results, 
      getPosition: (r: Measurement) => [r.x, r.y],
      getWeight: (r: Measurement) => r.v
    }), */
    /* new ColumnLayer({
      id: 'column-layer',
      data: results,
      getPosition: (r: IValue) => [r.lng, r.lat],
      getFillColor: r => [128, 48, (r.v - 18) / 14 * 255, 255],
      getLineColor: [0, 0, 0],      
      getElevation: (r: IValue) => r.v - 15,
      elevationScale: 600
    }) */
  ];

  return (
    <ClippedMap
      {...viewState}
      mapboxAccessToken={App.config.mapboxKey}
      style={{width: '100%', height: '100%', background: '#333'}}
      logoPosition="bottom-left"  // Moves mapbox logo
      fadeDuration={0}            // Prevents fading in of cluster labels
      interactiveLayerIds={interactiveLayerIds}
      // mapStyle={`mapbox://styles/${filter.mapstyle}`}
      mapStyle='mapbox://styles/longlineenvironment/cltpv79h300uy01pkf9yg7do6'
      minZoom={1}
      maxZoom={20}
      cursor={hoverCluster || hoverStation ? 'pointer' : ''}
      doubleClickZoom={false}
      //Events
      onLoad={handleLoad}
      onMove={handleMove}      
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <MapControls {...viewState}/>

      <ChipList>
        {filter.systems.map(s => <Chip color={Colors.PRESSED} key={s.id}>{s.name}</Chip>)}
        {filter.parameter && <Chip color={Colors.DARK}>{filter.parameter.name}</Chip>}
        {filter.campaign && <Chip color={Colors.HIGH}><>{filter.campaign.name} <Datum.ShortDate value={filter.campaign.date}/></></Chip>}
      </ChipList>

      {/* <DateSlider/>
      <Area/> */}
      {/* {filter.parameter && filter.systems.length > 0 && 
        <ResultGrid/>} */}

      {/* {stations.length > 0 && <DeckGLOverlay interleaved layers={layers}/>} */}

      {/* <Source id="visualization" type="geojson" data={visualization}>
        <Layer {...VisualizationFillLayer}/>
      </Source> */}

      <StationMarkers stations={stations} selectedStation={station} hoveredStation={hoverStation}/>
      <SystemBoundaries systems={filter.systems} stations={stations}/>


      {/* <Source id="measurements"  type="geojson" data={turf.featureCollection(results
         .map(m => turf.point([ m.x, m.y ], { v: Math.round(m.v * 10)/10})))}>
        <Layer {...VisualizationSymbolLayer}/>
      </Source>  */}

      {/* <SystemMarkers systems={filter.systems}/> */}

      {filter.systems.length > 0 && filter.campaign && filter.parameter && 
        <IdwLayer campaign={filter.campaign} parameter={filter.parameter}/>
      }

      <Dock>
        {filter.systems.length > 0 && <Dockable zIndex={100} alwaysDocked>
          <SystemInfoBox active={true} systems={filter.systems} onDeselect={s => filter.deselectSystem(s)}/>
        {filter.parameter && 
          <ParameterInfoBox parameter={filter.parameter}/>}
        </Dockable>}
        {station && <Dockable zIndex={100} latitude={station.latitude} longitude={station.longitude} offsetX={40} offsetY={-63} swappable>
          <StationInfoBox station={station}/>
        </Dockable>}
        <PositionsManager/>
      </Dock>
      
    </ClippedMap>
  );
}

export { Map }
