import * as React from 'react';
import { AuthContext, IAuth } from './AuthContext';
import { ISigninResponse } from '../../api/services/auth/SigninResponse';

interface IProps {
  children?: React.ReactNode;
}

const Auth = (props: IProps) => {
  const getInitialState = () => {
    // Try to load Auth from session storage.
    const authStr = sessionStorage.getItem('auth');
    const authData = authStr ? JSON.parse(authStr) : {};

    let initialState: IAuth = {
      access_token: null,
      signin: signin,
      signout: signout
    }    
    initialState = {...initialState, ...authData};
    return initialState;
  }

  const signin = (response: ISigninResponse) => {
    const newData = { 
      ...data, 
      access_token: response.access_token
    }
    setData(newData);
    // Store auth in session:
    sessionStorage.setItem('auth', JSON.stringify(newData));
  }

  const signout = () => {
    setData({...data, access_token: null});
    // Remove auth from session:
    sessionStorage.removeItem('auth');
  }

  const [data, setData] = React.useState<IAuth>(getInitialState());  

  return (
    <AuthContext.Provider value={data}>
      {props.children}
    </AuthContext.Provider>
  );  
}

export { Auth }
