export enum Icons {
  Arrow = "/sprites.svg#icon-arrow",
  Back = "/sprites.svg#icon-back",
  Check = "/sprites.svg#icon-check",
  Circle = "/sprites.svg#icon-circle",
  Close = "/sprites.svg#icon-close",
  Copy = "/sprites.svg#icon-copy",
  Crosshairs = "/sprites.svg#icon-crosshairs",
  Csv = "/sprites.svg#icon-csv",
  Delete = "/sprites.svg#icon-delete",
  Edit = "/sprites.svg#icon-edit",
  Excel = "/sprites.svg#icon-excel",
  Expand = "/sprites.svg#icon-expand",
  Export = "/sprites.svg#icon-export",
  Filter = "/sprites.svg#icon-filter",
  Info = "/sprites.svg#icon-info",
  Locked = "/sprites.svg#icon-locked",
  Logout = "/sprites.svg#icon-logout",
  NewField = "/sprites.svg#icon-new-field",
  Pdf = "/sprites.svg#icon-pdf",
  Search = "/sprites.svg#icon-search",
  Signout = "/sprites.svg#icon-signout",
  Unlocked = "/sprites.svg#icon-unlocked"
}
