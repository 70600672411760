import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

import { Icon, IconStyled } from '@independent-software/typeui/controls';

import { Colors } from '../../../theme/Colors';
import { lighten } from '@independent-software/typeui/helper/lighten';
import { Counter } from '../../../ui/core';

/* Tray animation time in ms. */
const TRAYTIME = 200;

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Tray title. Can be JSX. */
  title: React.ReactNode;
  /** Number of items in tray. */
  count: number;
  /** Is the tray currently open? */
  open?: boolean;
  /** Fired when tray is toggled. */
  onToggle: () => void;
}

const PanelBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Header onClick={props.onToggle}>
        <div>{props.title}</div>
        <Right>
          <Counter size="mini">{props.count}</Counter>
          <Icon name="caret-down" rotated={props.open ? 0 : -90}/>
        </Right>
      </Header>
      <Scroller>
        {props.children}
      </Scroller>
    </div>
  );
}

const Header = styled.div`
  height: 40px;
  display: flex;
  border-bottom: solid 1px #333;
  justify-content: space-between;
  font-weight: 500;
  align-items: center;
  ${IconStyled} {
    transition: transform ease-in-out ${TRAYTIME}ms;
  }
  padding-left: 16px;
  padding-right: 16px;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    background-color: ${lighten(0.1, Colors.PRESSED)};
  }
`

const Scroller = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  //overflow-y: scroll;
  border-bottom: solid 1px #333;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const Panel = styled(PanelBase)`
  position: relative;
  flex: ${p => p.open ? 1 : 0};
  background: ${Colors.PRESSED};
  color: white;
  transition: flex ease-in-out ${TRAYTIME}ms;
  svg {
    fill: white;
  }
`

export { Panel }
