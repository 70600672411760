import { parse } from "date-fns";
import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { Campaign, CampaignDuration, ListResponse, Parameter, System } from "../models";

class CampaignApi {
  /**
   * Retrieve list of campaigns.
   */
  static list(access_token: string, options?: { systems?: System[] }, signal?: AbortSignal): Promise<ListResponse<Campaign>> {
    options = options || {};
    const url = new Url("campaigns");
    if(options.systems) {
      // If a systems array is present, we query for those systems plus system 0. 
      // System 0 is added only to guarantee we have a filter.
      url.append('filter[system]', `${[...options.systems.map(s => s.id), 0].join(',')}`);
    }
    return Api.Get<ListResponse<Campaign>>(url.toString(), access_token, signal);
  }

  /**
   * Retrieve a list of campaign durations.
   */
  static getDurations(access_token: string, systems: System[], parameter: Parameter, signal?: AbortSignal): Promise<CampaignDuration[]> {
    const url = new Url("campaign-durations");
    url.append("systems", systems.map(s => s.id));
    url.append("parameter", parameter.id);
    return Api.Get<CampaignDuration[]>(url.toString(), access_token, signal)
      .then(res => {
        // Convert text dates to Date objects.
        return res.map((entry: CampaignDuration) => {
          const start = parse((entry.start as unknown) as string, 'yyyy-MM-dd', new Date());
          const end = parse((entry.end as unknown) as string, 'yyyy-MM-dd', new Date());
          const numericStart = start.getFullYear() * 12 + start.getMonth() + start.getDate() / 31;
          const numericEnd = end.getFullYear() * 12 + end.getMonth() + end.getDate() / 31;
          return {
            ...entry,
            start: start,
            end: end,
            numericStart: numericStart,
            numericEnd: numericEnd
          }
      });
    });
  }
}

export { CampaignApi }
