import * as React from 'react';
import { FilterContext, IFilter } from './FilterContext';
import { Campaign, Parameter, Parametercategory, System } from '../../api/models';
import { Mapstyles } from '../../map/Mapstyles';
import { ParameterApi } from '../../api/services/ParameterApi';
import { useAuth } from '../auth/useAuth';

interface IProps {
  children?: React.ReactNode;
}

const Filter = (props: IProps) => {
  const auth = useAuth();
  const [systems, setSystems] = React.useState<System[]>([]);
  const [parameter, setParameter] = React.useState<Parameter>(null);
  const [parameters, setParameters] = React.useState<Parameter[]>([]);
  const [parametercategories, setParametercategories] = React.useState<Parametercategory[]>([]);
  const [campaign, setCampaign] = React.useState<Campaign>(null);
  const [mapstyle, setMapstyle] = React.useState<string>(Mapstyles[0].key);

  /**
   * Toggles system in collection of currently selected systems.
   */
  const toggleSystem = (system: System) => {
    if(hasSystem(system)) {
      deselectSystem(system);
    } else {
      selectSystem(system);
    }
  }

  /**
   * Returns true if system is in collection of currently selected systems.
   */
  const hasSystem = (system: System): boolean => {
    return !!systems.find(s => s.id == system.id);
  }

  /**
   * Adds system to collection of currently selected systems.
   */
  const selectSystem = (system: System) => {
    if(systems.find(s => s.id == system.id)) return;
    setSystems([system, ...systems]);
  }

  /**
   * Removes system from collection of currently selected systems.
   */
  const deselectSystem = (system: System) => {
    setSystems(systems.filter(s => s.id != system.id));
  }

  const value: IFilter = {
    systems: systems,
    hasSystem: hasSystem,
    toggleSystem: toggleSystem,
    deselectSystem: deselectSystem,
    setSystems: setSystems,
    parameter: parameter,
    setParameter: setParameter,
    parameters: parameters,
    setParameters: setParameters,
    parametercategories: parametercategories,
    setParametercategories: setParametercategories,
    campaign: campaign,
    setCampaign: setCampaign,
    mapstyle: mapstyle,
    setMapstyle: setMapstyle
  }

  //
  // Load parameter categories. This happens only once.
  // 
  React.useEffect(() => {
    setParametercategories(null); // This is the loading state.
    ParameterApi.listCategories(auth.access_token)
    .then(res => {
      setParametercategories(res.data);
    })
  }, []);

  // 
  // Load parameters. This happens when systems or campaign changes.
  //
  React.useEffect(() => {
    const controller = new AbortController();
    setParameters(null); // This is the loading state.
    ParameterApi.list(auth.access_token, systems, campaign, controller.signal)
    .then(res => {
      setParameters(res);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    });

    return () => { if(controller) controller.abort(); }   
  }, [systems, campaign]);

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
}

export { Filter }
