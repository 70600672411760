import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { MenuSelector } from './MenuSelector';
import { MenuList } from './MenuList';

interface IProps { 
  /** Item formatter. */
  children: (item: any) => React.ReactNode;
  /** Items to show in menu. */
  data: any[];
  /** Currently-selected item. */
  value: any;
  /** Fired when an item is selected from the menu. */
  onSelect: (item: any) => void;
}

const Menu = (props: IProps) => {
  const [open, setOpen] = React.useState(false);

  // Clicking outside the menu or pressing ESC will close it.
  React.useEffect(() => {
    document.addEventListener('click', closeMenu);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', closeMenu);
      document.removeEventListener('keydown', handleKeyDown);
    }
  });
  const closeMenu = () => setOpen(false);
  const handleKeyDown = (e: KeyboardEvent) => {
    if(e.key == 'Escape') closeMenu();
  }

  const handleSelect = (item: any) => {
    setOpen(false);
    props.onSelect(item);
  }

  return (
    <>
      {/* Note: Menu has no 'position' so that MenuList can position itself
          according to the InfoBox.Content parent. */}
      <MenuSelector open={open} onToggle={() => setOpen(!open)} value={props.value} formatter={props.children}/>
      <MenuList open={open} data={props.data} formatter={props.children} onSelect={handleSelect}/>
    </>
  )
}

export { Menu }
