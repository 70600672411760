import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const DrawerContentBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const DrawerContent = styled(DrawerContentBase)` 
  flex: 1;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  color: #fff;
`

export { DrawerContent }
