import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps { 
  /** @ignore */
  className?: string;
  /** Is menu currently open? */
  open: boolean;
  /** Data to show in list. */
  data: any[];
  /** Formatter to format each data item. */
  formatter: (v: any) => React.ReactNode;
  /** Fired when an item is clicked. */
  onSelect: (item: any) => void;
}

const MenuListBase = (props: IProps) => 
  <div className={props.className}>
    {props.data.map((d, idx) => 
      <ListItem key={idx} onClick={() => props.onSelect(d)}>
        {props.formatter(d)}
      </ListItem>
    )}
  </div>;

const ListItem = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;    
  cursor: pointer;
  &:nth-child(2n+1) {
    background: #444;
  }
  &:hover {
    background: #555;
  }
`

const MenuList = styled(MenuListBase)`
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  bottom: ${p => p.open ? `0px` : `100%`};
  transition: bottom ease-in-out ${p => p.theme.transition.duration}s;
  background: #333;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: ${p => p.theme.radius}px;
  border-bottom-right-radius: ${p => p.theme.radius}px;
`

export { MenuList }
