import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const DrawerButtonsBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>

const DrawerButtons = styled(DrawerButtonsBase)` 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
`

export { DrawerButtons }
