import * as React from 'react';

interface IConfirm {
  /**
   * Launches a confimation dialog. The dialog can be either confirmed ("Yes")
   * or cancelled.
   * 
   * @param onConfirm Callback to call when dialog choice is confirmed
   * @param title Optional dialog title
   * @param children Optional dialog content
   */
  start: (onConfirm: () => void, title?: string, children?: React.ReactNode) => void;
}

const ConfirmContext = React.createContext<IConfirm>({
  start: () => {}
});

export { ConfirmContext }
