import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Number } from '@independent-software/typeui/formatters/Number';

interface IProps { 
  /** @ignore */
  className?: string;
  /** Property label, e.g. `Carrying capacity` */
  label: React.ReactNode;
  /** Property value. */
  value: any;
  /** 
   * If numeric, format number with this number of decimals. This property
   * is ignored for non-numeric values.
   * @default 0
   */
  decimals?: number;
  /** Optional unit to show after label, e.g. `kg` */
  unit?: React.ReactNode;
}

const PropertyBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Label>{props.label}</Label>
      <Value>
        {(typeof props.value === 'number' && !isNaN(props.value)) ? <Number value={props.value} decimals={props.decimals ?? 0}/> : props.value}
        {' '} 
        {props.unit}
      </Value>
    </div>
  )
}

const Label = styled.div`
  position: absolute;
  left: 16px;
  top: 17px;
  right: 16px;
  color: #ccc;
  font-variant: all-small-caps;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`

const Value = styled.div`
  position: absolute;
  left: 16px;
  top: 36px;
  right: 16px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PropertyStyled = styled(PropertyBase)`
  position: relative;
  width: 100%;
  min-width: 50px;
  height: 74px;
  min-height: 74px;
  background: rgba(0,0,0,0.2);
  border-radius: ${p => p.theme.radius}px;
  color: ${p => p.theme.fontColor};

  &:hover {
    background: ${p => p.theme.primaryColor}; 
    ${Label} { 
      color: #fff; 
    }
  }  
`

/** 
 * A Property is a read-only view of some value. 
 */
const Property = (props: IProps) => <PropertyStyled {...props}/>

export { Property }
