import styled from "@independent-software/typeui/styles/Theme";

const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
`

export { Heading }
