import * as React from 'react';

import { System } from '../../../api/models';
import { Drawer } from '../../../ui/containers/panels/Drawer';
import { Fab, Loader } from '../../../ui/core';
import { SVG } from '../../../svg';
import { SystemForm } from './SystemForm';

interface IProps {
  system: System;
  loading: boolean;
  onClose: () => void;
  onEdit: (system: System) => void;
}

const EditSystem = (props: IProps) => {
  const currentSystem = React.useRef<System>(props.system);
  const [system, setSystem] = React.useState<System>(currentSystem.current);
  const [dirty, setDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  const handleChange = (newSystem: System, forceupdate: boolean) => {
    currentSystem.current = Object.assign(currentSystem.current, newSystem);
    if(forceupdate) { 
      setSystem({...newSystem});
    }    
  }    

  const handleSubmit = () => {
    setDirty(true);
    if(!isValid) return;    
    props.onEdit(currentSystem.current);
  }

  return (
    <Drawer.Tray>
      {props.loading && <Loader/>}
      <Drawer.Header justify>
        {props.system.name}
      </Drawer.Header>
      <Drawer.Options white stretch>
        <SystemForm system={system} dirty={dirty} onChange={handleChange} onValidate={(v) => setIsValid(v)}/>
      </Drawer.Options>
      <Drawer.Buttons>
        <Fab path={SVG.Icons.Close} onClick={props.onClose}>Cancel</Fab>
        <Fab path={SVG.Icons.Check} onClick={handleSubmit}>Save</Fab>
      </Drawer.Buttons>
    </Drawer.Tray>
  )
}

export { EditSystem }
