import { IMapstyle } from "../ui/map/StylePicker/StylePicker";

// cljejcwey005x01qs4n8xd4yz
// Test: cljmvs6k8009201pmdemo6uje

// Satellite original: cljejcwey005x01qs4n8xd4yz
// TransparencyTest: cljmy087k00c801qydh7gc1n4

const Mapstyles: IMapstyle[] = [
  { key: "longlineenvironment/cljejcwey005x01qs4n8xd4yz", name: "Satellite" },
  { key: "longlineenvironment/cljejerbi006n01qs53k2ecni", name: "Land use" },
  { key: "longlineenvironment/cljek2bdo006c01pl26knckxe", name: "Bathymetry" }
];

export { Mapstyles }
