import * as React from 'react';
import styled, { css } from '@independent-software/typeui/styles/Theme';
import { Colors } from '../../../../theme/Colors';
import { darken } from '@independent-software/typeui/helper/darken';

interface IProps {
  /** @ignore */
  className?: string;

  /** 
   * Path to the icon, e.g. "/sprites.svg#blah".
   */
  path: string;

  /** 
   * Button positive (green)? Buttons default to blue if no color is 
   * specified. 
   */
  positive?: boolean;
  /** 
   * Button negative (red)? Buttons default to blue if no color is specified. 
   */
  negative?: boolean;
  /** 
   * Custom button color. Buttons default to blue if no color is specified. 
   */
  color?: string;
  /**
   * Icon color. Defaults to white.
   * @default "white"
   */
  iconColor?: string;
  /** 
   * A disabled button cannot be interacted with. It is always grey. 
   */
  disabled?: boolean;

  /**
   * Size: large or small.
   * @default small
   */
  size?: 'large' | 'small';
  /**
   * Active buttons get a different color.
   */
  active?: boolean;
  /**
   * Pressed buttons have an inset shadow.
   */
  pressed?: boolean;
  /**
   * Fired when button is clicked.
   */
  onClick: React.MouseEventHandler;
}

const IconButtonBase = (props: IProps) => {
  return (
    <button className={props.className} onClick={props.onClick}>
      <svg>
        <use xlinkHref={props.path}></use>
      </svg>
    </button>
  );
}

const IconButtonStyled = styled(IconButtonBase).attrs(p => ({
  /* What is the button's color? */
  finalColor: (p:any):string => {
    if(p.positive) return Colors.LOW;
    if(p.negative) return Colors.HIGH;
    if(p.color) return p.color;
    return Colors.MODERATELY_LOW;
  }
}))`
  // Dimensions:
  width: ${p => p.size == 'large' ? 50 : 32}px;
  min-width: ${p => p.size == 'large' ? 50 : 32}px;
  height: ${p => p.size == 'large' ? 50 : 32}px;
  // Center icon:
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  // Box:
  border: none;
  border-radius: ${p => p.size == 'large' ? 16 : 12}px;;
  box-sizing: border-box;

  // Misc:
  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  user-select: none;
  pointer-events: ${p => p.disabled ? 'none' : 'all'};

  // Background:
  background-color: ${p => p.finalColor(p)};
  transition: background-color ease-in-out 200ms;
  ${p => p.active && css`background-color: ${Colors.MODERATELY_HIGH};`}
  ${p => p.pressed && css`background-color: ${Colors.PRESSED};`}
  ${p => !p.pressed && css`
    &:hover {
      background-color: ${darken(0.05, p.finalColor(p))};
      ${p.active && css`background-color: ${darken(0.05, '#EA963D')};`}
    }
  `}
  ${p => p.disabled && css`background-color: #ccc;`}

  // Shadow:
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
  &:hover {
    box-shadow: 0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026;
  }
  &:active {
    box-shadow: none;
  }  
  ${p => p.pressed && css`box-shadow: 0px 1px 3px 0px #0000004D inset, 0px 4px 8px 3px #00000026 inset;`}

  // Icon:
  & > svg {
    fill: ${p => p.iconColor ?? "#fff"};
  }
`;

/**
 * An `IconButton` is a button with only an icon. It can be small (default) or
 * large. The button supports default, `pressed` and `active` states.
 */
const IconButton = (props: IProps) => <IconButtonStyled {...props}/>;

export { IconButton }
