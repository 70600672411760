import { Url } from "../../helpers/Url";
import { Api } from "../Api";
import { ListResponse, Station, System } from "../models";

class StationApi {
  /**
   * Retrieve list of parameters.
   */
  static list(access_token: string, options?: { systems?: System[] }, signal?: AbortSignal): Promise<ListResponse<Station>> {
    options = options || {};

    const url = new Url(`stations`);
    if(options.systems) {
      // If a systems array is present, we query for those systems plus system 0. 
      // System 0 is added only to guarantee we have a filter.
      url.append('filter[system]', `${[...options.systems.map(s => s.id), 0].join(',')}`);
    }
    return Api.Get<ListResponse<Station>>(url.toString(), access_token, signal);
  }
}

export { StationApi }
