import * as React from 'react'
import { animated, useSpring  } from '@react-spring/web';
import styled, { css } from '@independent-software/typeui/styles/Theme';

// The standard width x height of an unexpanded button, in pixels.
const BUTTON_SIZE = 50;
// The width x height of an icon inside the button.
const ICON_SIZE = 26;

interface IProps {
  /** @ignore */
  className?: string;
  /** Content of menu icon. */
  title: React.ReactNode;
  /** Function to execute when button is clicked. */
  action: () => void;
  /** Svg to show, e.g. "campaigns" */
  svg: string;
  /** Is this menu item currently active? */
  active?: boolean;
  /** A secondary button is dark. */
  secondary?: boolean;
}

const SpringButtonBase = (props: IProps) => {
  // The <Content> element contains the actual menu text. The width of this
  // element depends on the length of the text. It is this width that must
  // be used for the animation.
  const content = React.useRef<HTMLDivElement>();

  const [ springs, api ] = useSpring(() => ({
    from: { maxWidth: BUTTON_SIZE }
  }));

  const handleMouseEnter = () => {
    api.start({
      from: {
        maxWidth: BUTTON_SIZE,
      },
      to: {
        maxWidth: content.current.scrollWidth + 24 + 13,
      },
      config: {
        mass: 1, 
        tension: 400,
        friction: 20,
        damping: 1,
        precision: 0.01
      }
    })
  }

  const handleMouseLeave = () => {
    if(springs.maxWidth.get() > BUTTON_SIZE)
    api.start({
      from: {
        maxWidth: content.current.scrollWidth + 24 + 13,
      },
      to: {
        maxWidth: BUTTON_SIZE,
      },
      config: {
        mass: 1, 
        tension: 170,
        friction: 26,
        damping: 1,
        precision: 0.01
      }
    })    
  }
  
  return (
    <div className={props.className}>
      <Animatable style={{ ...springs }} onMouseEnter={props.active ? null : handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={props.action}>
        <Content ref={content}>
          <svg>
            <use xlinkHref={props.svg}></use>
          </svg>
          {props.title}
        </Content>
      </Animatable>
    </div>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
  svg {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    min-width: ${ICON_SIZE}px;
    fill: #fff;
  }
  height: ${BUTTON_SIZE}px;
`

const Animatable = styled(animated.div)`
  position: relative;
  background: #35ABE2;
  width: auto;
  max-width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: 16px;
  overflow: hidden;
  color: white;
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
  padding-left: 13px;
  padding-right: 24px;
  box-sizing: border-box;
  white-space: nowrap;
  backface-visibility: hidden;
`

const SpringButton = styled(SpringButtonBase)`
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 16px;
  ${Animatable} {
    background: ${p => (p.secondary || p.active) ? '#273c44' : '#35ABE2'};
    ${p => p.active && css`
      box-shadow: 0px 1px 3px 0px #0000004D inset,
                  0px 4px 8px 3px #00000026 inset;
      color: #f7a44e;
      svg { 
        fill: #f7a44e; 
      }
    `}
  }
`

export { SpringButton }
