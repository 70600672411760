import { ISigninResponse } from './SigninResponse';
import { Api } from '../../Api';

class AuthApi {

  /**
   * Sign in with email/password, and return ISigninResponse
   * @param email Email to login with
   * @param password Password to login with
   * @returns ISigninResponse contains user ID (= email), plus authorities.
   */
  static signin = async (email: string, password: string): Promise<ISigninResponse> => {
    return Api.Post(`signin`, { email: email, password: password }, null)

    /* // Build request headers:
    return axios.post<ISigninResponse>(`${App.config.apiURL}signin`, params)
    .then((res) => res.data)
    .catch((error) => {
      if(error.response) {
        throw new Error((error.response.data as any).message + ".");
      } else if(error.request) {
        throw new Error("No server response was received.");
      } else {
        throw new Error("There was a problem sending your request.");
      }
    });
    return {} as ISigninResponse; */
  }

  static forgotPassword = async (email: string, signal?: AbortSignal): Promise<null> => {
    await Api.Post<null>(`password/reset`, { email }, null, signal);
    return null;
  }    

  /**
   * @param id Reset ID
   * @param password new password
   * @param signal 
   * @returns true if reset was successful, false if not
   */
  static resetPassword = async (id: string, password: string, signal?: AbortSignal): Promise<boolean> => {
    const response = Api.Post<boolean>(`password/change`, { clid: id, password }, null, signal);
    return response;
  }    
  
}

export { AuthApi }
