import styled from '@independent-software/typeui/styles/Theme';

const Dock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-top: 16px;
  margin-right: 16px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 16px;
  pointer-events: none;
`

export { Dock }
