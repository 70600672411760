import * as React from 'react';
import { Number } from '@independent-software/typeui/formatters/Number';

import { InfoBox } from '../../../ui/containers';
import { Station } from '../../../api/models';

interface IProps {
  /** Station to show */
  station: Station;
}

const StationInfoBox = (props: IProps) => {
  const handleZoom = () => {
    const event = new CustomEvent("zoomToStation", { detail: { station: props.station } });
    document.dispatchEvent(event);
  }

  return (
    <InfoBox>
      <InfoBox.Header>
        {props.station.name}
      </InfoBox.Header>
      <InfoBox.Content>
        <InfoBox.Row label="Longitude" value={<Number value={props.station.longitude} decimals={4}/>}/>
        <InfoBox.Row label="Latitude" value={<Number value={props.station.latitude} decimals={4}/>}/>
        <InfoBox.Row label="System" value={props.station.system.name}/>
        <InfoBox.Row label="Samples" value={<Number value={props.station.samples_count} decimals={0}/>}/>
      </InfoBox.Content>
      <InfoBox.Footer>
        <InfoBox.Button onClick={handleZoom}>FOCUS</InfoBox.Button>
      </InfoBox.Footer>
    </InfoBox>
  )
}

export { StationInfoBox }
