import * as React from 'react';
import { Number } from '@independent-software/typeui/formatters/Number';

import { InfoBox } from '../../../ui/containers';
import { System } from '../../../api/models';

interface IProps {
  /** System(s) to show */
  systems: System[];
  /** Is system currently selected? */
  active: boolean;
  /** 
   * If present, a select/deselect button is added. 
   * Fired when button is clicked. 
   */
  onToggle?: (system: System) => void;
  /**
   * If present, a deselect button is added.
   * Fired when button is clicked.
   */
  onDeselect?: (system: System) => void;
  /**
   * If present, a VIEW buttons is added.
   * Fired when button is clicked.
   */
  onView?: (system: System) => void;
}

const SystemInfoBox = (props: IProps) => {

  // Don't render anything if there are no systems. This InfoBox should not
  // be called with 0 systems.
  if(props.systems.length == 0) return null;

  const [system, setSystem] = React.useState<System>(props.systems[0]);

  // When systems collection changes, select first system from new collection.
  React.useEffect(() => {
    setSystem(props.systems[0]);
  }, [props.systems]);
  
  const handleZoom = () => {
    const event = new CustomEvent("zoomToSystem", { detail: { system: system } });
    document.dispatchEvent(event);
  }

  return (
    <InfoBox>
      <InfoBox.Header>
        {props.systems.length > 1 ? <>{props.systems.length} Systems</> : system.name}
      </InfoBox.Header>
      <InfoBox.Content>
        {props.systems.length > 1 && <InfoBox.Menu data={props.systems} value={system} onSelect={(s) => setSystem(s)}>
          {s => s.name}
        </InfoBox.Menu>}
        <InfoBox.Row label="Stations" value={<Number value={system.stations_count} decimals={0}/>}/>
        <InfoBox.Row label="Campaigns" value={<Number value={system.campaigns_count} decimals={0}/>}/>
      </InfoBox.Content>
      <InfoBox.Footer>
        {props.onToggle && <InfoBox.Button onClick={() => props.onToggle(system)}>{props.active ? 'DESELECT' : 'SELECT'}</InfoBox.Button>}
        {props.onDeselect && <InfoBox.Button onClick={() => props.onDeselect(system)}>DESELECT</InfoBox.Button>}
        <InfoBox.Button onClick={handleZoom}>FOCUS</InfoBox.Button>
        {props.onView && <InfoBox.Button onClick={() => props.onView(system)}>VIEW</InfoBox.Button>}
      </InfoBox.Footer>
    </InfoBox>
  )
}

export { SystemInfoBox }
