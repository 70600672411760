import * as React from 'react';
import styled, { ThemeProvider, css } from '@independent-software/typeui/styles/Theme';
import { useTheme } from '../../../../theme/useTheme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  stretch?: boolean;
  white?: boolean;
}

const DrawerOptionsBase = (props: IProps) => {
  const theme = useTheme();

  return (
    <div className={props.className}>
      <ThemeProvider theme={props.white ? {...theme, background: '#ffffff', fontColor: '#333333' } : {theme}}>
        {props.children}
      </ThemeProvider>
    </div>
  )
}

const DrawerOptions = styled(DrawerOptionsBase)` 
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  color: white;
  ${p => p.stretch && css`
    flex: 1;
    overflow-y: auto;
  `}
`

export { DrawerOptions }
