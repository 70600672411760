import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme';
import { animated, useSpring  } from '@react-spring/web';

interface IProps {
  /** Fired when logo is clicked. */
  onClick: () => void;
}

const LogoButton = (props: IProps) => {
  // Is the button currently held down?
  const [pressed, setPressed] = React.useState(false);

  const [ springs, api ] = useSpring(() => ({
    from: { x: 0 },
    config: {
      mass: 1, 
      tension: 500,
      friction: 14,
      damping: 1,
      precision: 0.01      
    }
  }));  

  const handleMouseEnter = () => {
    api.start({
      from: { x:   0 },
      to:   { x: -30 }
    });
  }  

  const handleMouseLeave = () => {
    api.start({
      from: { x: -30 },
      to:   { x:   0 }
    });
    setPressed(false);
  }

  const handleMouseDown = () => {
    setPressed(true);
  }

  const handleMouseUp = () => {
    setPressed(false);
  }

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={props.onClick}>
      {/* Animation transformation for Logo is only applied when an overlay is shown: */}
      <Svg style={{ transform: springs.x.to(value => `rotateZ(${value}deg)`)}} viewBox="-372.1 450.5 105.3 100">
        <path d="M-341.4,493.1c-1.1,0-2.2-0.4-3.1-1.2c-1.8-1.7-1.9-4.5-0.2-6.3c7.9-8.4,18.7-13,30.2-13
          c11.2,0,21.7,4.4,29.6,12.4c1.7,1.8,1.7,4.6,0,6.3c-1.8,1.7-4.6,1.7-6.3,0c-6.2-6.3-14.5-9.7-23.3-9.7c-9.1,0-17.5,3.6-23.7,10.2
          C-339,492.7-340.2,493.1-341.4,493.1z" fill="#f9a255"/>
        <path d="M-353.3,481.8c-1.1,0-2.2-0.4-3.1-1.2c-1.8-1.7-1.9-4.5-0.2-6.3c11.1-11.7,26-18.1,42.1-18.1
          c15.8,0,30.6,6.2,41.6,17.6c1.7,1.8,1.7,4.6-0.1,6.3c-1.8,1.7-4.6,1.7-6.3-0.1c-9.3-9.6-21.8-14.9-35.2-14.9
          c-13.6,0-26.3,5.4-35.7,15.3C-351,481.3-352.2,481.8-353.3,481.8z" fill="#f9a255"/>
        <path d="M-296.5,517c0,9.7-7.9,17.6-17.6,17.6c-0.8,0-1.7-0.1-2.5-0.2c-3.8-0.5-7.3-2.3-10-5l-0.2-0.2l-12.3-12.3
          l12.3-12.3l0.2-0.2c3.3-3.3,7.8-5.2,12.5-5.2C-304.4,499.4-296.5,507.3-296.5,517z" fill="#ffffff"/>
        <path d="M-353.3,517v11.8h-11.8C-363.2,522.7-359.1,518.9-353.3,517z" fill="#77BFE1"/>
        <path d="M-353.3,517v-11.8h-11.8C-363.2,511.4-359.1,515.1-353.3,517z" fill="#1A3C69"/>
        <path d="M-309.7,496.9c8.2,0,22.3,5.6,22.3,20.3c0,4.1-0.8,8.6-3.7,13.5l0,0c-1.9-10.7-5.5-24.3-17-23.1
          c-2.3,0.2-5.2,1.9-6.5,3.4l-6,6h-19.5l13.8-14C-322.5,499.2-315.1,496.9-309.7,496.9z" fill="#77BFE1"/>
        <path d="M-333.8,497.5l-19.5,19.5l19.4,19.4l0.2,0.2c4.6,4.7,10.7,7.5,17.1,8.1c0.8,0.1,1.7,0.1,2.5,0.1
          c7.4,0,14.4-2.9,19.6-8.1c5.2-5.2,8.1-12.2,8.1-19.6c0-7.4-2.9-14.4-8.1-19.6s-12.2-8.1-19.6-8.1
          C-321.6,489.3-328.6,492.2-333.8,497.5z M-296.5,517c0,9.7-7.9,17.6-17.6,17.6c-0.8,0-1.7-0.1-2.5-0.2c-3.8-0.5-7.3-2.3-10-5
          l-0.2-0.2l-12.3-12.3l12.3-12.3l0.2-0.2c3.3-3.3,7.8-5.2,12.5-5.2C-304.4,499.4-296.5,507.3-296.5,517z" fill="#1A3C69"/>
        <circle cx="-305.1" cy="516.9" r="3.9" fill="#1A3C69"/>
        <path d="M-333.8,497.5l-19.5,19.5l19.4,19.4l0.2,0.2c4.6,4.7,10.7,7.5,17.1,8.1c0.8,0.1,1.7,0.1,2.5,0.1
          c7.4,0,14.4-2.9,19.6-8.1c5.2-5.2,8.1-12.2,8.1-19.6c0-7.4-2.9-14.4-8.1-19.6s-12.2-8.1-19.6-8.1
          C-321.6,489.3-328.6,492.2-333.8,497.5z" fill="none"/>
        <polygon points="-329.7,521.1 -325.1,521.1 -322.8,517.1 -325.1,513.1 -329.7,513.1 -332,517.1 " fill="#39ADE1"/>
      </Svg>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  z-index: 2;
  pointer-events: auto;
  // Center logo in wrapper:
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
`

const Svg = styled(animated.svg)`
  position: relative;
  width: 50px;
  height: 50px;
  pointer-events: none;
`

export { LogoButton }
