import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PublicRoute } from './routing/PublicRoute';
import { ProtectedRoute } from './routing/ProtectedRoute';
import { NoMatch } from './routes/nomatch/NoMatch';
import { Signin } from './routes/signin/Signin';

// Router documentation:
// Intro: https://www.robinwieruch.de/react-router/
// Authentication: https://www.robinwieruch.de/react-router-authentication/
// Note: <BrowserRouter> requires historyApiFallback in webpack config,
// in order to make the devServer always use index.hmtl

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="signin" element={<PublicRoute><Signin/></PublicRoute>}/>
        {/* <Route path="forgotpassword" element={<PublicRoute><ForgotPassword/></PublicRoute>}/>
        <Route path="reset/:id" element={<PublicRoute><ResetPassword/></PublicRoute>}/> */}
        {/* Protected routes have a map. */}
        <Route element={<ProtectedRoute/>}> 
          <Route path="" element={null}/>
          <Route path="home" element={null}/>
        </Route>
        {/* Nothing matched? */}
        <Route path="*" element={<PublicRoute><NoMatch/></PublicRoute>}/>
      </Routes>
    </BrowserRouter>
  );
}

export { Router }
