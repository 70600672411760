import * as React from 'react';

import { Icon, Input } from '@independent-software/typeui/controls';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Datum } from '@independent-software/typeui/formatters/Datum';

import { Campaign } from '../../../api/models';
import { CampaignApi } from '../../../api/services/CampaignApi';
import { CurrentCampaignCard } from './CurrentCampaignCard';
import { Table } from '../../../ui/containers/lists/Table';
import { Highlight } from '../../../ui/core/text/Highlight';
import { CampaignInfoBox } from '../../infoboxes/CampaignInfoBox';
import { Drawer } from '../../../ui/containers/panels/Drawer';
import { useFilter } from '../../../contexts/filter/useFilter';
import { Counter, Loader } from '../../../ui/core';
import { useAuth } from '../../../contexts/auth/useAuth';

interface IProps {
  /** Is panel open? */
  open?: boolean;
}

const CampaignsTray = (props: IProps) => {
  const auth = useAuth();
  const filter = useFilter();
  const [sort, setSort] = React.useState("name");
  const [reverse, setReverse] = React.useState(false);
  const [q, setQ] = React.useState("");
  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);
  const [campaign, setCampaign] = React.useState<Campaign>(null);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  //
  // Retrieve Campaigns.
  // This happens when systems selection changes.
  // 
  React.useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    CampaignApi.list(auth.access_token, { systems: filter.systems }, controller.signal)
    .then(res => {
      setCampaigns(res.data);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    })
    .finally(() => setLoading(false));
    return () => { if(controller) controller.abort(); }
  }, [filter.systems]);

  const handleSort = (sort: string, reverse: boolean) => {
    setSort(sort);
    setReverse(reverse);
  }

  const handleChangeQ = (value: string) => setQ(value);

  const handleSelectCampaign = () => {
    filter.setCampaign(campaign);
  }

  const handleClearCampaign = () => {
    filter.setCampaign(null);
  }  

  const getTitle = () => {
    if(campaigns == null) return "CAMPAIGNS";
    if(filter.campaign == null) {
      return <>CAMPAIGNS <Counter size="mini">{campaigns.length}</Counter></>
    } else {
      return <CurrentCampaignCard campaign={filter.campaign} onRemove={handleClearCampaign}/>
    }    
  }
  
  const lowerQ = (q ?? "").toLowerCase();
  const filteredCampaigns = 
    Table.sort(campaigns, sort, reverse, (item: Campaign, key: string) => {
      if(key == 'system') return item.system.name;
      return (item as any)[key];

   })
    .filter(campaign => campaign.name.toLowerCase().includes(lowerQ) || campaign.system.name.toLowerCase().includes(lowerQ));

  if (!props.open) return null;

  return (
    <Drawer.Tray expandable expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      {loading && <Loader/>}
      <Drawer.Options white>
        <Input fluid clearable type="text" placeholder="Search" value={q} onChange={handleChangeQ}>
          <Icon name="search"/>
        </Input>
      </Drawer.Options>

      <Drawer.Header justify>
        {getTitle()}
      </Drawer.Header>

      <Table 
        animated hover data={filteredCampaigns} active={campaign} sort={sort} reverse={reverse} onSort={handleSort}
        singular="campaign" plural="campaigns" noCounter={loading || filter.systems.length == 0} onClick={c => setCampaign(c)} 
        noData={filter.systems.length == 0 ? <Table.NoData>Please select a system to show campaigns.</Table.NoData> : <Table.NoData/>}
        infoBox={<CampaignInfoBox campaign={campaign} onSelect={handleSelectCampaign}/>}
      >
        <Table.Column name="Name" width={2} sort="name">{(item: Campaign) => item.name ? <Highlight value={item.name} q={q}/>: <>(no name)</>}</Table.Column>
        <Table.Column name="Date" width={2} sort="date">{(item: Campaign) => <Datum.ShortDate value={item.date}/>}</Table.Column>
        {expanded && <Table.Column name="System" width={2} sort="system">{(item: Campaign) => item.system.name ? <Highlight value={item.system.name} q={q}/>: <>(no name)</>}</Table.Column>}
        {expanded && <Table.Column name="Samples" width={1} align="right" sort="samples_count">{(item: Campaign) => <Number value={item.samples_count} decimals={0}/>}</Table.Column>}
      </Table>
    </Drawer.Tray>
  );
}

export { CampaignsTray }
  