import * as React from 'react';
import styled, { css } from "@independent-software/typeui/styles/Theme"

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Exact Header height. Optional. */
  height?: number;
  /** Fired when header is clicked. */
  onClick?: React.ReactEventHandler;
}

const HeaderBase = (props: IProps) => 
  <div className={props.className} onClick={props.onClick}>
    {props.children}
  </div>

const HeaderStyled = styled(HeaderBase)`
  pointer-events: all;
  background: #1E242A;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 12px 17px 12px 17px;
  font-weight: 500;
  font-size: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: #fff;
  ${p => p.height && css`height: ${p.height}px`};
`

const Header = (props: IProps) => <HeaderStyled {...props}/>;

export { Header }
