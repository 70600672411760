import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** Row content */
  label: React.ReactNode;
  value: React.ReactNode;
}

const RowBase = (props: IProps) => 
  <div className={props.className}>
    <Label>
      {props.label}
    </Label>
    <Value>
      {props.value}
    </Value>
  </div>

const Label = styled.div`
  flex: 1;  
  font-size: 14px;
  color: #e9edef;
  font-weight: 700;
  text-transform: uppercase;
`

const Value = styled.div`
`

const Row = styled(RowBase)`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
`

export { Row }
