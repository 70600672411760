import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Balloon currenty active? */
  active?: boolean;
}

const BalloonBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

const Balloon = styled(BalloonBase)`
  position: absolute;
  bottom: 100%;
  /* background: linear-gradient(0deg, #1C242A, #1C242A),
              linear-gradient(163.96deg, #505050 3.25%, rgba(23, 23, 23, 0.56) 18.64%, rgba(0, 0, 0, 0) 105.88%);
  border: solid 1px #333; */

  //border: solid 4px green;
  //background: linear-gradient(red, blue) padding-box;

  background: linear-gradient(#1C242A, #1C242A) padding-box,
    linear-gradient(330deg, #505050 3.25%, rgba(23, 23, 23, 0.56) 18.64%, rgba(0, 0, 0, 0) 105.88%) border-box;
  border-radius: ${p => p.theme.radius}px;
  border: 1px solid transparent; 
  margin-bottom: 32px;
  padding: 16px;
  font-size: 14px;
  box-sizing: border-box;
  color: white;
  user-select: none;
  opacity: ${p => p.active ? 1 : 0};
  transition: opacity ease-in-out 250ms;

  a {
    color: #E9A759 !important;
  }

  &:after {
    position: absolute;
    content: '';
    top: calc(100% - 9px);
    right: 30px;
    width: 20px;
    height: 20px;
    background: #1C242A;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
    z-index: 1;
    border-right: solid 1px #505050;
    border-bottom: solid 1px #505050;
    box-sizing: border-box;
  }
`

export { Balloon }
