import * as React from 'react';
import { ISigninResponse } from '../../api/services/auth/SigninResponse';

interface IAuth {
  access_token: string;
  signin: (data: ISigninResponse) => void;
  signout: () => void;
}

const AuthContext = React.createContext<IAuth>({
  access_token: null,
  signin: () => {},
  signout: () => {}
});

export { AuthContext, IAuth }
