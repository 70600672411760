import { Balloon } from "./elements/Balloon";
import { Body } from "./elements/Body";
import { Box } from "./elements/Box";
import { Bubble } from "./elements/Bubble";
import { Buttons } from "./elements/Buttons";
import { Header } from "./elements/Header";
import { Heading } from "./elements/Heading";

class Public {
  static Box = Box;
  static Balloon = Balloon;
  static Body = Body;
  static Header = Header;
  static Buttons = Buttons;
  static Heading = Heading;
  static Bubble = Bubble;
}

export { Public }
