import * as React from 'react';
import { Layer, Map, Source } from 'react-map-gl';
import { App } from '../App';

/**
 * Basemap that wraps the map-react-gl Map component. It accepts all map 
 * properties, and adds the following:
 * 
 * - Default satellite raster layer.
 * - Clipped satellite raster layer on top of that.
 * 
 */
const ClippedMap = (props: any) => {

  const loadImage = (url: string) => {
    return new Promise((res, rej) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = url;
      img.onload = e => res(img);
      img.onerror = rej;
    });
  }  

  const get2DContext = (width = 512, height = 512) => {
    return Object.assign(
      document.createElement('canvas'),
      {width, height}
    ).getContext('2d');
  }  
  
  // https://stackoverflow.com/questions/55892083/javascript-load-image-into-offscreen-canvas-perform-webp-conversion
  const urlToCanvas = async (url: string) => {
    // Load <img> from server:
    let img = await loadImage(url);

    // Create bitmap from image element.
    let bmp: ImageBitmap = await createImageBitmap(img as any);

    // Create canvas:
    const ctx = get2DContext(512, 512);    

    ctx.drawImage(bmp, 0, 0);

    return ctx;
  }

  /**
   * Request clipped tile at z,x,y. The tokens are Mapbox's SKU and access
   * token.
   */
  const requestClippedSatellite = async (z: string, x: string, y: string, tokens: string) => {
    // URL to DEM tile (it will always be 512x512):
    const demUrl = `https://api.mapbox.com/raster/v1/mapbox.mapbox-terrain-dem-v1/${z}/${x}/${y}.webp?${tokens}`;
    // URL to satellite tile (we request @2x so it will be 512x512):
    const imgUrl = `https://api.mapbox.com/v4/mapbox.satellite/${z}/${x}/${y}@2x.webp?${tokens}`;
    
    // Load images into canvas contexts:
    const ctx_dem = await urlToCanvas(demUrl);
    const ctx_satellite = await urlToCanvas(imgUrl);

    // Read DEM data into array (512 x 512 x 4 bytes):
    const demImageData = ctx_dem.getImageData(0, 0, 512, 512);

    // Read satellite data into array (512 x 512 x 4 bytes):
    const satImageData = ctx_satellite.getImageData(0, 0, 512, 512);

    for(let p = 0; p < 512 * 512 * 4; p += 4) {
      // Get DEM color for pixel and calculate elevation:
      const r = demImageData.data[p];
      const g = demImageData.data[p + 1];
      const b = demImageData.data[p + 2];
      const a = demImageData.data[p + 3];
      const elevation = -10000 + ((r * 256 * 256 + g * 256 + b) * 0.1);
      // Pixels with no elevation (i.e. water) will be transparent:
      if(elevation < 1) {
        satImageData.data[p] = 0;
        satImageData.data[p+1] = 0;
        satImageData.data[p+2] = 0;
        satImageData.data[p+3] = 0;
      }
    }

    ctx_satellite.putImageData(satImageData, 0, 0);

    // Get blob from canvas:
    const blob = await new Promise(resolve => ctx_satellite.canvas.toBlob(resolve));

    // Create reponse from blob:
    return new Response(blob as any);
  }

  const requestNormalSatellite = (originalFetch: any, config: RequestInit, z: string, x: string, y: string, tokens: string) => {
    return originalFetch(`https://api.mapbox.com/v4/mapbox.satellite/${z}/${x}/${y}@2x.webp?${tokens}`, config);
  }

  React.useEffect(() => {
    const { fetch: originalFetch } = window;
    window.fetch = async(...args) => {
      let resource = args[0];
      let config = args[1];

      // Is there a URL? If not return original fetch:
      const url: string = (resource as any).url;
      if(!url) return originalFetch(resource, config);

      // Is this a normal satellite layer request?
      let regexp = /https:\/\/api\.mapbox\.com\/v4\/mapbox\.satellite\/(\d+)\/(\d+)\/(\d+)@2x.webp\?(.*)/;
      let m = url.match(regexp);
      if(m) {
        return requestNormalSatellite(originalFetch, config, m[1], m[2], m[3], m[4]);
      }

      // Is this a clipped satellite layer request? This is the one that looks like 
      // a DEM request (to fool Mapbox's internal cache), but will result in
      // both a DEM and a satellite request.
      regexp = /https:\/\/api\.mapbox\.com\/raster\/v1\/mapbox\.mapbox\-terrain\-dem\-v1\/(\d+)\/(\d+)\/(\d+).webp\?(.*)/;
      m = url.match(regexp);
      if(m) {
        return requestClippedSatellite(m[1], m[2], m[3], m[4]);
      }

      return originalFetch(resource, config)
    }    
  }, [])

  return (
    <Map
      {...props}
    >
      {/* Was: url="mapbox://mapbox.satellite" */}

      {/* 
        * The Mapbox API needs an sku to track users for metered billing.  It 
        * will add an `sku` argument to the query (and will fail without
        * an sku value when deployed). This is why we must use `tiles` and 
        * not a plain `url` value.  Also, the URLs used here must be actual
        * Mapbox tile IDs for mapbox to add the sku to them. 
        * This would of course not apply if some other tile provider were used.
        * 
        * Further, we can't use the same Tile ID (i.e. point to the "satellite" 
        * URL for both the clipped and nonclipped layer), because that'll 
        * cause caching problems. This is why we load DEM here, and satellie
        * in the layer below. We don't *really* load DEM; that's just to fool
        * Mapbox's caching.
        */}
      <Source type="raster" tiles={[ `https://api.mapbox.com/raster/v1/mapbox.mapbox-terrain-dem-v1/{z}/{x}/{y}.webp?access_token=${App.config.mapboxKey}` ]}>
        <Layer id="overlay" type="raster"/>
      </Source> 
      
      <Source type="raster" tiles={[ `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.webp?access_token=${App.config.mapboxKey}` ]}>
        <Layer beforeId="overlay" type="raster"/>
      </Source>

      {props.children}
    </Map>
  );
}

export { ClippedMap }
