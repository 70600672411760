import * as React from 'react';
import styled, { css } from "@independent-software/typeui/styles/Theme"

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** A disabled button cannot be interacted with. */
  disabled?: boolean;
  /** Fired when button is clicked. */
  onClick: () => void;
}

const ButtonBase = (props: IProps) => {
  const handleClick = () => {
    if(props.disabled) return;
    props.onClick();
  }

  return (
    <button className={props.className} onClick={handleClick}>
      {props.children}
    </button>
  );
}

const ButtonStyled = styled(ButtonBase)` 
  // Size:
  height: 42px;
  padding-left: 16px;
  padding-right: 16px;

  // Font:
  font-family: ${p => p.theme.fontName};
  font-size: 12px;
  font-weight: normal;
  color: #1E3663;

  // Presentation:
  background: ${p => p.disabled ? '#ddd' : '#fff'};
  border-radius: 12px;
  border: none;
  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  text-transform: uppercase;
  user-select: none;

  // Content alignment:
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  // Shadow:
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
  &:hover {
    box-shadow: 0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026;
  }
  &:active {
    ${p => !p.disabled && css`
      box-shadow: none;
    `} 
  }
`

/**
 * The `InfoBox.Button` is a special button style that is used for the buttons
 * under InfoBoxes, as well as in the IQ menu. It renders as a `<button>`
 * element.
 */
const Button = (props: IProps) => <ButtonStyled {...props}/>

export { Button }
