import * as React from 'react';
import { Dialog } from '@independent-software/typeui/controls';

import { XhrContext } from './XhrContext';

interface IProps {
  /** @ignore */
  children?: React.ReactNode;
}

/**
 * The Xhr context wraps all its content, adding an Xhr Dialog.
 * Keeping the Dialog outside of all app content avoids it being contaminated
 * with styling, or being out-z-indexed. 
 */
const Xhr = (props: IProps) => {
  const [error, setError] = React.useState(null);

  const clear = () => {
    setError(null);
  }

  const call = (f: () => Promise<any>, final?: () => void): Promise<any> => {
    // Clear error state:
    setError(null);
    // Call async function
    return f()
      // Catch any error, causing a dialog to appear
      .catch((err) => setError(err))
      // If a finally function was provided, call it.
      .finally(() => {
        if(final) final();
      });
  }

  return (
    <XhrContext.Provider value={{
      call: call
    }}>
      {props.children}
      <Dialog.Xhr open={error != null} error={error} onClose={clear}/>
    </XhrContext.Provider>
  );  
}

export { Xhr }
