import * as React from 'react';
import styled from "@independent-software/typeui/styles/Theme";

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const FooterBase = (props: IProps) => 
  <div className={props.className}>
    {props.children}
  </div>

const FooterStyled = styled(FooterBase)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 16px;
  padding-left:  16px;
  padding-right: 16px;
  box-sizing: border-box;
  color: white;
  margin-top: 10px;
  margin-bottom: 8px;
  & > * {
    pointer-events: all;
  }
`

const Footer = (props: IProps) => <FooterStyled {...props}/>

Footer.defaultProps = {
  // Adding a "__type" prop allows Reach.Children to identify this particular
  // component and filter it out.
  __type: "footer"
}

export { Footer }
