import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const NoDataBase = (props: IProps) => 
  <div className={props.className}>
    {props.children ?? "Your query returned no data.  Your filter might be too restrictive."}
  </div>

const NoDataStyled = styled(NoDataBase)`
  color: #fff;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  user-select: none;
  padding: 8px 16px 8px 16px;
  border-radius: ${p => p.theme.radius}px;
  background: ${p => p.theme.errorColor.background};
`

/**
 * NoData adds a no data message to the Table. It has a default message
 * if no children are provided.
 */
const NoData = (props: IProps) => <NoDataStyled {...props}/>
  
export { NoData }
