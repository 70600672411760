import * as React from 'react';
import styled, { keyframes } from '@independent-software/typeui/styles/Theme';

/**
 * A `Loader` fills all available space with a dark shader. An animated SVG
 * is shown on top of that.
 * 
 * The parent element must set a `position` in order for the Loader to be able
 * to fill the space. The Loader has a `z-index` of 100 relative to its 
 * parent.
 */
const Loader = () => {
  return (
    <Wrapper>
      <Spinner></Spinner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Animation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`

const SIZE = 128;

const Spinner = styled.div`
  position: relative;
  width: ${SIZE}px;
  height: ${SIZE}px;
  &:after, &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: ${SIZE}px;
    height: ${SIZE}px;
    border-radius: 35%;
    border: ${SIZE/10}px solid #fff;
    opacity: 0.5;
    animation: ${Animation} 2s linear infinite;
  }
  &:after {
    animation-delay: 1s;
  }
`

export { Loader } 
