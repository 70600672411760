import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { useNavigate } from 'react-router-dom';

import { ToastService } from '@independent-software/typeui/controls/Toast';

import { SigninForm } from './SigninForm';
import { SVG } from '../../svg';
import { ISigninRequest } from '../../api/services/auth/ISigninRequest';
import { AuthApi } from '../../api/services/auth/AuthApi';
import { Public } from '../../ui/containers';
import { Fab } from '../../ui/core';
import { useAuth } from '../../contexts/auth/useAuth';

// Adds a timeout to make sure that signin button animation actually gets seen:
const BUTTON_TIMEOUT = 250; // ms

const Signin = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [hintActive, setHintActive] = React.useState(false);
  const [credentials, setCredentials] = React.useState<ISigninRequest>({ email: sessionStorage.getItem('email'), password: '' });
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    if(auth.access_token !== null) {
      navigate("/");
    }
  }, [auth.access_token]);

  const handleChange = (signinForm: ISigninRequest, forceupdate: boolean) => {
    setCredentials(signinForm);
  }  

  const handleValidate = (valid: boolean) => {
    setValid(valid);
  }  

  const handleSubmit = () => {
    if(loading) return;
    setLoading(true);
    setTimeout(() => {
      AuthApi.signin(credentials.email, credentials.password)
      .then(res => auth.signin(res))
      .catch(error => {
        setLoading(false);
        ToastService.toast("Login failed.");
      });
    }, BUTTON_TIMEOUT);
    // Adds a 250ms timeout to make sure that signin button
    // animation actually gets seen.    
  }    

  const handleForgot = () => {
    if(loading) return;
    navigate('/forgotpassword');
  }

  return (
    <>
      <Public.Box>
        <Public.Balloon active={hintActive}>
          <p>
            AQUA is a Longline Environment product.
          </p>
          <p>
            For more information consult our website: <a href="https://www.aquatag.tech">www.aquatag.tech</a>
          </p>
          <Links>
          <p>
            <a href="https://www.longline.co.uk/flow/longline/who/">Who we are</a>
          </p>
          <p>
            <a href="https://www.longline.co.uk/flow/privacy/">Privacy policy</a>
          </p>          
          <p>
            <a href="https://www.longline.co.uk/flow/contacts/">Contacts</a>
          </p>          
          </Links>
          <Longline><use xlinkHref={SVG.Logos.Longline}></use></Longline>
        </Public.Balloon>
        <Public.Header onClick={() => setHintActive(!hintActive)}/>
        <Public.Body>
          <SigninForm disabled={loading} data={credentials} onChange={handleChange} onValidate={handleValidate} onSubmit={handleSubmit}/>
        </Public.Body>
      </Public.Box>
      <Public.Buttons>
        <Fab onClick={handleForgot}>FORGOT PASSWORD?</Fab>
        <Fab animated primary path={loading ? SVG.TypeUI.CircleNotch : null} disabled={!valid} onClick={handleSubmit}>SIGN IN</Fab>
      </Public.Buttons>    
    </>    
  )
}  

const Links = styled.div`
  margin-top: 12px;
  font-size: 80%;
`

const Longline = styled.svg`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 48px;
  height: 64px;
`

export { Signin }
