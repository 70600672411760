import * as React from 'react';
import { Source, Layer } from 'react-map-gl';
import * as turf from '@turf/helpers';
import convex from '@turf/convex';
import buffer from '@turf/buffer';

import { Station, System } from '../api/models';

interface IProps {
  /** Currently active systems */
  systems: System[];
  /** Currently loaded stations */
  stations: Station[];
}

const SystemBoundaries = (props: IProps) => {

  // Calculate a buffered polygon for each active system.
  // Whenever systems or stations changes, recalculate this (systems and 
  // stations are not loaded at the same time).
  const systemFeatures = React.useMemo(() => {
    return turf.featureCollection(props.systems.map(system => {
      // Get all stations in this system system.
      const systemStations = props.stations
        .filter(s => s.latitude != null && s.longitude != null)
        .filter(station => station.system.id == system.id)
      // If there aren't any stations, then don't include this system
      // (filter it out at the end).
      if(systemStations.length == 0) return null;
      // Convert all stations to Points.
      const stationPoints = systemStations
        .map(station => turf.point([station.longitude, station.latitude]));
      // Convex and Buffer around points.
      const poly = convex(turf.featureCollection(stationPoints));
      const bufferedPoly = buffer(poly, 10);
      // Pass the system name in as a property.
      bufferedPoly.properties["name"] = system.name;
      return bufferedPoly;
    }).filter(x => x != null));
  }, [props.systems, props.stations]);

  return (
    <Source id="system-boundaries" type="geojson" data={systemFeatures}>
      <Layer 
        type="fill"
        paint={{
          "fill-color": "steelblue",
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 1, 1, 10, 0],
        }}
      />        
      <Layer 
        type="line"
        paint={{
          "line-color": "#fff",
          "line-width": ["interpolate", ["linear"], ["zoom"], 5, 1, 18, 8],
          "line-dasharray": [4,2]
        }}
      />
      <Layer
        type="symbol"
        maxzoom={10}
        layout={{
          "symbol-placement": "point",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 18,
          "text-field": ["get", "name"],
        }}
        paint={{
          "text-color": "#fff",
          "text-halo-width": 3,
          "text-halo-color": "#333",
          "text-halo-blur": 1            
        }}
      />
      <Layer
        type="symbol"
        minzoom={10}
        layout={{
          "symbol-placement": "line",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 16,
          "text-field": ["get", "name"],
        }}
        paint={{
          "text-color": "#fff",
          "text-halo-width": 3,
          "text-halo-color": "#333",
          "text-halo-blur": 1
        }}
      />        
    </Source>  
  );
}

export { SystemBoundaries }
