import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Map } from '../map/Map';
import { Drawer } from '../ui/containers/panels/Drawer';
import { DataArea } from './DataArea';
import { Splitter, TSplitterState } from './Splitter';
import { CampaignsTray, ParametersTray, UserTray } from './trays';
import { SVG } from '../svg';
import { LogoButton, SpringButton } from '../ui/core';
import { MenuBar } from '../ui/containers';
import { useAuth } from '../contexts/auth/useAuth';
import { SystemsManager } from './trays/SystemsTray/SystemsManager';

interface IProps {
  /** @ignore */
  className?: string;
}

enum TrayTypes {
  TrayParameters,
  TrayCampaigns,
  TraySystems,
  TrayUser,
}

const ScreenBase = (props: IProps) => {
  const auth = useAuth();
  // Is the drawer currently open?
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // Which drawer content is displayed?
  const [trayIndex, setTrayIndex] = React.useState<number>(null);
  // What is the splitter state?
  const [splitterState, setSplitterState] = React.useState<TSplitterState>('closed');

  const handleOpenDrawer = (index: number) => {
    setTrayIndex(index);
    setDrawerOpen(true);
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  }

  React.useEffect(() => {
    // Listen for document-wide zoomtoSystem event when component mounts.
    document.addEventListener('zoomToSystem', handleZoomToSystem);
    return () => {
      // Clean up document-wide zoomToSysetm event when component unmounts.
      document.removeEventListener('zoomToSystem', handleZoomToSystem);
    }
  }, [])

  //
  // Respond to zoomToSystem event by closing the drawer.
  //
  const handleZoomToSystem = (e: CustomEvent) => {
    setDrawerOpen(false);
  }  

  return (
    <div className={props.className}>
      <MenuBar>
        <div>
          <LogoButton onClick={() => {}}/>
          <SpringButton active={trayIndex == TrayTypes.TraySystems && drawerOpen} title="Systems" svg={SVG.Menu.Systems} action={() => handleOpenDrawer(TrayTypes.TraySystems)}/>
          <SpringButton active={trayIndex == TrayTypes.TrayParameters && drawerOpen} title="Parameters" svg={SVG.Menu.Flask} action={() => handleOpenDrawer(TrayTypes.TrayParameters)}/>
          <SpringButton active={trayIndex == TrayTypes.TrayCampaigns && drawerOpen} title="Campaigns" svg={SVG.Menu.Campaigns} action={() => handleOpenDrawer(TrayTypes.TrayCampaigns)}/>
        </div>

        <div>
          <SpringButton secondary active={trayIndex == TrayTypes.TrayUser && drawerOpen} title="Account" svg={SVG.Menu.Account} action={() => handleOpenDrawer(TrayTypes.TrayUser)}/>
          <SpringButton secondary title="Sign out" svg={SVG.Icons.Signout} action={() => auth.signout()}/>
        </div>
      </MenuBar>

      <Drawer open={drawerOpen} onClose={handleCloseDrawer}>
        <ParametersTray open={trayIndex == TrayTypes.TrayParameters}/>
        <CampaignsTray open={trayIndex == TrayTypes.TrayCampaigns}/>
        <SystemsManager open={trayIndex == TrayTypes.TraySystems}/>
        <UserTray open={trayIndex == TrayTypes.TrayUser}/>
      </Drawer>
      
      <Splitter
        left={<Map/>}
        right={<DataArea/>}
        state={splitterState}
        onState={(state) => setSplitterState(state)}
      /> 
    </div>
  );
}

const Screen = styled(ScreenBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
`

export { Screen }

