import * as React from 'react';
import { Number } from '@independent-software/typeui/formatters/Number';

import { InfoBox } from '../../../ui/containers';
import { Parameter, System } from '../../../api/models';

interface IProps {
  /** Parameter to show */
  parameter: Parameter;
  /** Fired when SELECT is clicked. */
  onSelect?: (parameter: Parameter) => void;
}

const ParameterInfoBox = (props: IProps) => {

  return (
    <InfoBox>
      <InfoBox.Header>
        {props.parameter.name}
      </InfoBox.Header>
      <InfoBox.Content>
        <InfoBox.Row label="Unit" value={props.parameter.unit}/>
        <InfoBox.Row label="Min value" value={<Number value={props.parameter.minvalue} decimals={props.parameter.decimals}/>}/>
        <InfoBox.Row label="Max value" value={<Number value={props.parameter.maxvalue} decimals={props.parameter.decimals}/>}/>
        <InfoBox.Row label="Type" value={props.parameter.type}/>
      </InfoBox.Content>
      <InfoBox.Footer>
        {props.onSelect && <InfoBox.Button onClick={() => props.onSelect(props.parameter)}>SELECT</InfoBox.Button>}
      </InfoBox.Footer>
    </InfoBox>
  )
}

export { ParameterInfoBox }
