import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Public } from '../../ui/containers';
import { Fab } from '../../ui/core';

const NoMatch = () => {
  const navigate = useNavigate();

  return (
    <>
      <Public.Box>
        <Public.Balloon active>
          <Public.Heading>PAGE NOT FOUND</Public.Heading>
          <p>
            If you expected to see something here, go back and try again, or 
            make sure the address is typed in correctly.  
          </p>
          <p>
            If the problem persists, get in touch with us 
            at <a href="mailto:accounts@aquarisk.tech">accounts@aquarisk.tech</a>.
          </p>
        </Public.Balloon>
        <Public.Header/>
        <Public.Body>
          Page not found.
        </Public.Body>
      </Public.Box>
      <Public.Buttons>
        <Fab primary onClick={() => navigate("/")}>HOME</Fab>
      </Public.Buttons>    
    </>    
  )
}

export { NoMatch }
