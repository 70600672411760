import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const BoxBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

const Box = styled(BoxBase)`
  box-shadow: 0px 1px 3px 0px #0000004D, 
              0px 4px 8px 3px #00000026;
  border-radius: ${p => p.theme.radius}px;
`

export { Box }
