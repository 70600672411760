import * as React from 'react';

import { Datum } from '@independent-software/typeui/formatters/Datum';
import { Number } from '@independent-software/typeui/formatters/Number';

import { InfoBox } from '../../../ui/containers';
import { Campaign } from '../../../api/models';

interface IProps {
  /** Campaign to show */
  campaign: Campaign;
  /** Fired when SELECT button is clicked. */
  onSelect: () => void;
}

const CampaignInfoBox = (props: IProps) =>
  <InfoBox>
    <InfoBox.Header>
      {props.campaign.name}
    </InfoBox.Header>
    <InfoBox.Content>
      <InfoBox.Row label="System" value={props.campaign.system.name}/>
      <InfoBox.Row label="Date" value={<Datum.ShortDate value={props.campaign.date}/>}/>
      <InfoBox.Row label="Samples" value={<Number value={props.campaign.samples_count} decimals={0}/>}/>
      <InfoBox.Row label="Freshwater discharge" value={<Number value={props.campaign.freshwater_discharge} decimals={2}/>}/>
      <InfoBox.Row label="Tidal amplitude" value={<Number value={props.campaign.tidal_amplitude} decimals={2}/>}/>
      <InfoBox.Row label="Tidal situation" value={props.campaign.tidal_situation}/>
      <InfoBox.Row label="High time" value={props.campaign.high_time}/>
      <InfoBox.Row label="Low time" value={props.campaign.low_time}/>
      <InfoBox.Row label="Radiation" value={props.campaign.radiation}/>
      <InfoBox.Row label="Rainfall" value={props.campaign.rainfall}/>
      <InfoBox.Row label="High temperature" value={props.campaign.high_temperature}/>
      <InfoBox.Row label="Low temperature" value={props.campaign.low_temperature}/>
      <InfoBox.Row label="Wind direction" value={props.campaign.wind_direction}/>
      <InfoBox.Row label="wind speed" value={props.campaign.wind_speed}/>
      <InfoBox.Row label="Weather" value={props.campaign.weather}/>
    </InfoBox.Content>
    <InfoBox.Footer>
      <InfoBox.Button onClick={props.onSelect}>SELECT</InfoBox.Button>
    </InfoBox.Footer>
  </InfoBox>

export { CampaignInfoBox }
