import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { darken } from '@independent-software/typeui/helper/darken';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /* Chip background color */
  color?: string;
}

const ChipBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const Chip = styled(ChipBase)`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  border-radius: ${p => p.theme.radius}px;
  background: ${p => p.color ?? '#333'};;
  color: #fff;
  border: solid 2px ${p => darken(0.2, p.color ?? '#333')};
  padding: 4px 8px;
  opacity: 0.8;
  user-select: none;
`

export { Chip }
