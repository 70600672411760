import * as React from 'react';
import styled, { css } from '@independent-software/typeui/styles/Theme';
import { keyframes } from 'styled-components';
import { Colors } from '../../../../theme/Colors';
import { darken } from '@independent-software/typeui/helper/darken';
import { Ripple } from '@independent-software/typeui/controls';

interface IProps {
  /** @ignore */
  className?: string;
  
  /**
   * Button content. This can be any React node, or a string. It is optional;
   * if not provided, the button will show only an icon.
   */
  children?: React.ReactNode;

  /** 
   * Path to the icon, e.g. `/sprites.svg#blah`. If not provided, then the 
   * button has no icon. 
   */
  path?: string;

  /** 
   * Icon color. 
   * @default white
   */
  iconColor?: string;

  /**  
   * Button positive (green). Buttons default to white if no color is 
   * specified. 
   */
  positive?: boolean;
  /** 
   * Button negative (red). Buttons default to white if no color is specified. 
   */
  negative?: boolean;
  /**
   * Button primary (blue). Buttons default to white if no color is specified.
   */
  primary?: boolean;
  /** 
   * Custom button color. 
   * @default white 
   */
  color?: string;

  /** 
   * A disabled button cannot be interacted with. It is always grey, overriding 
   * any color. 
   */
  disabled?: boolean;

  /**
   * Set minimum button width, in pixels. It not set, content determines
   * button width.
   */
  minWidth?: number;


  /**
   * If set, any icon will be rotated with an animation.
   */
  animated?: boolean;

  /**
   * Fired when button is clicked.
   */
  onClick: React.MouseEventHandler;
}

const FabBase = (props: IProps) => {
  return (
    <Ripple>
      <button className={props.className} onClick={props.onClick}>
        {props.path && <svg>
          <use xlinkHref={props.path}></use>
        </svg>}
        {props.children}
      </button>
    </Ripple>
  );
}

const animation = keyframes`
  0% { transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`;

const FabStyled = styled(FabBase).attrs(p => ({
  /* What is the button's color? */
  finalColor: (p:any):string => {
    if(p.positive) return Colors.LOW;
    if(p.negative) return Colors.HIGH;
    if(p.primary) return Colors.MODERATELY_LOW;
    if(p.disabled) return "#ccc";
    if(p.color) return p.color;
    return "#ffffff";
  }
}))`
  // Dimensions:
  height: 56px;
  padding: 18px 16px 18px 16px;
  border: none;
  border-radius: 16px;
  box-sizing: border-box;
  ${p => p.minWidth && css`min-width: ${p.minWidth}px;`}

  /* Font */
  font-family: ${p => p.theme.fontName}, sans-serif;
  font-weight: 500;
  font-size: 1em;
  white-space: nowrap;  
  
  color: ${p => (p.positive || p.negative || p.primary) ? '#ffffff' : '#1E3663'};
  ${p => p.disabled && css`color: #3C698A;`}

  // Misc:
  user-select: none;
  outline: none;
  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  pointer-events: ${p => p.disabled ? 'none' : 'all'};
  
  // Position content:
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  // Background:
  background: ${p => p.finalColor};
  ${p => p.disabled && css`background-color: #ccc;`}
  ${p => !p.disabled && css`&:hover {
    background-color: ${darken(0.05, p.finalColor(p))};
  }`}

  // Shadow:
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
  &:hover {
    box-shadow: 0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026;
  }
  &:active {
    box-shadow: none;
  }

  // Icon:
  svg {
    height: 20px;
    width: 20px;
    fill: ${p => p.iconColor ?? (p.finalColor(p) == '#ffffff' ? "#333" : "#fff")};
    ${p => p.animated && css`
      animation: ${animation} 1s infinite;
    `}
  }
`;

/**
 * A Fab or _floating action button_ is usually floating over other content.
 * However, it can be used as a regular button too.  It usually has an icon,
 * though this is not required.
 * 
 * By default, this button will be white.
 */
const Fab = (props: IProps) => <FabStyled {...props}/>;

export { Fab }
