import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Longitude, Latitude } from '@independent-software/typeui/formatters/GIS';
import { ToastService } from '@independent-software/typeui/controls';

import { Dockable } from '../Dockable';
import { SVG } from '../../../svg';
import { InfoBox } from '../../containers';

interface IPosition {
  lng: number;
  lat: number;
  zoom: number;
}

interface IProps {
  coord: IPosition;
  onMove: (x: number, y: number) => void;
  onDelete: () => void;
  onFocus: () => void;
}

const PositionBox = (props: IProps) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const dragOffset = React.useRef<[number, number]>(null);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
  })

  const handleMouseMove = (ev: MouseEvent) => {
    if(dragging) props.onMove(
      ev.clientX - dragOffset.current[0], 
      ev.clientY - dragOffset.current[1]);
  }

  const handleMouseUp = () => {
    setDragging(false);
  }

  const handleStartDrag = (ev: React.MouseEvent) => {
    const x = wrapperRef.current.parentElement.offsetLeft;
    const y = wrapperRef.current.parentElement.offsetTop;
    dragOffset.current = [ev.clientX - x - 9, ev.clientY - y - 38];
    setDragging(true);
  }

  const setClipboard = (text: string) => {
    const type = "text/plain";
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data);
  }

  const handleCopy = () => {
    if (window.isSecureContext) {
      setClipboard(`${props.coord.lng}, ${props.coord.lat}`);
      ToastService.toast("Coordinates copied to clipboard.");
    } else {
      console.error("Could not write to clipboard: context not secure.");
    }
  }

  return (
    <Dockable latitude={props.coord.lat} longitude={props.coord.lng} offsetX={-9} offsetY={-38}>
      <Wrapper ref={wrapperRef}>
        <Icon onClick={props.onDelete}>
          <Target><use xlinkHref={SVG.Icons.Circle}></use></Target>
          <Delete><use xlinkHref={SVG.Icons.Close}></use></Delete>
        </Icon>
        <Body>
          <Buttons>
            <InfoBox.Button onClick={props.onFocus}>FOCUS</InfoBox.Button>
          </Buttons>
          <Coords>
            <Coord><Latitude value={props.coord.lat}/></Coord>
            <Coord><Longitude value={props.coord.lng}/></Coord>
          </Coords>
          <Copy onClick={handleCopy}>
            <use xlinkHref={SVG.Icons.Copy}></use>
          </Copy>
          <Handle onMouseDown={handleStartDrag}>
          </Handle>
        </Body>
      </Wrapper>
    </Dockable>
  )
}

const Target = styled.svg`
  width: 100%;
  height: 100%;
  fill: white;
`

const Delete = styled.svg`
  width: 100%;
  height: 100%;
  fill: #E94A52;
`

const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 28px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${Delete} { display: none };
  &:hover {
    ${Target} { display: none; }
    ${Delete} { display: block; }
  }
  // If control is offscreen, then only "Delete" is shown.
  .offscreen & {
    ${Target} { display: none; }
    ${Delete} { display: block; }
  }
`

const Copy = styled.svg`
  position: absolute;
  top: 26px;
  right: 40px;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  fill: white;
  cursor: pointer;
  &:hover {
    background: #333;
  }
`

const Handle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 32px;
  background: #333;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    background: radial-gradient(circle at center, #ccc 1px, transparent 1px, transparent 4px);
    background-size: 6px 6px;
    top: 16px;
    left: 6px;
    right: 6px;
    bottom: 16px;
  }
  .offscreen & { 
    display: none;
  }
`

const Buttons = styled.div`
  width: 120px;
  display: none;
  .offscreen & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:start;
  }
`

const Coord = styled.div`
  height: 12px;
  font-family: Cousine;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 2px;
`

const Coords = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
`

const Body = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.35);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 16px 16px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

const Wrapper = styled.div`
  position: relative;
  background: transparent;
  width: 200px;
  .offscreen & {
    width: 320px;
  }
  height: 78px;
  padding-left: 28px;
  pointer-events: all;
  user-select: none;
`

export { PositionBox, IPosition }
