import * as React from 'react';

interface ITableColumnProps {
  /** Column name, e.g. "Number of Users" */
  name: React.ReactNode;  
  /** 
   * Column width. If a number, e.g. `1`, then it is the column's weight, 
   * relative to other columns. If it is a string, e.g. `50px`, then it is 
   * an exact width to be applied.
   * @default 1
   */
  width?: number | string;
  /** Optional sort key. If not specified, column is not sortable. */
  sort?: string;
  /** If true, default sorting is reverse sort. */
  reverse?: boolean;
  /** Cell content alignment. Defaults to `left`. */
  align?: 'left' | 'right';
  /** Function that takes a table row and returns cell content. */
  children: (item: any) => React.ReactNode;
  /** Function that takes a table row and returns a band color. */
  band?: (item: any) => string;
}

/**
 * TableColumn species a column name, and how to render content for that
 * column. 
 * TableColumn itself doesn't render anything.
 */
const TableColumn = (_: ITableColumnProps): null => null;

export { TableColumn, ITableColumnProps }