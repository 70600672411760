import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const ChipListBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const ChipList = styled(ChipListBase)`
  position: absolute;
  right: 32px;
  bottom: 66px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
`

export { ChipList }
