import * as React from 'react';
import { XhrContext } from "./XhrContext";

/**
 * Returns a reference to the XhrContext. This context allows launching
 * of an async function, presenting a dialog box if the async call fails.
 * 
 * Example:
 * const xhr = useXhr();
 * xhr.call((): Promise<any> => ..., () => { finally do something })
 */
const useXhr = () => {
  return React.useContext(XhrContext);
}

export { useXhr }
