import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { SVG } from '../../../../../svg';

interface IProps {
  /** @ignore */
  className?: string;
  /** 
   * If the header is clickable, then it'll have a small "info" icon that
   * receives the clicks.
   */
  onClick?: () => void;
}

const HeaderBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Logo><use xlinkHref={SVG.Logos.LogoTextHorizontal}></use></Logo>
      {props.onClick && <Icon onClick={props.onClick}>
        <use xlinkHref={SVG.Icons.Info}></use>
      </Icon>}
    </div>
  )
}

const Logo = styled.svg`
  height: 48px;
  width: 120px;
`

const Icon = styled.svg`
  width: 26px;
  height: 26px;
  fill: #fff;
  border: solid 1px #8A9BA8;
  padding: 5px;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color ease-in-out 200ms, fill ease-in-out 200ms;
  &:hover {
    border: solid 1px #E9A759;
    fill: #E9A759;
  }
`

const Header = styled(HeaderBase)`
  background:#1C242A;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 22px;
  border-top-left-radius: ${p => p.theme.radius}px;
  border-top-right-radius: ${p => p.theme.radius}px;
`

export { Header }
