//
// OpenGL tools.
//

class OpenGL {

  //
  // Given the size of a dataset of 4-float entries, determine the minimum
  // size of a power-of-2 texture.
  //
  // That is, a dataSize of 5 means 5 sets of 4 floats, requiring a texture
  // that has a width x height >= 5. This function would return 8x1.
  //
  // Returns [width, height].
  private static getTextureDimensions = (dataSize: number): number[] => {
    const MAX_TEXTURE_SIZE = 1024;
    let width = 1;
    let height = 1;
    while(width * height < dataSize) {
      width *= 2;
      if(width > MAX_TEXTURE_SIZE) {
        width = 1;
        height *= 2;
      }
    }
    return [width, height];
  }

  static makeDataTexture = (gl: WebGLRenderingContext, data: number[], numComponents: number): [WebGLTexture, number, number] => {
    // Float textures are only supported when OES_texture_float extension
    // is available.
    const ext = gl.getExtension('OES_texture_float');
    if (!ext) {
      alert('need OES_texture_float');
      return;
    }

    // How big should our FloatArray be? 
    // * We'll want 4 floats for every element that gets placed in the array, 
    //   even if each element occupies fewer than 4 floats.
    // * We'll want the size of the array, measured in elements not floats,
    //   to equal a square texture of NxN where N is a power of 2. Unused space
    //   in the array will be set to zero.

    const [width, height] = this.getTextureDimensions(data.length / numComponents);

    // Expand the data to 4 values per pixel. Even if each data element contains
    // fewer than 4 components, we'll want each element to occupy 4 values.
    const numElements = data.length / numComponents;
    const expandedData = new Float32Array(width * height * 4);
    for (let i = 0; i < numElements; ++i) {
      const srcOff = i * numComponents;
      const dstOff = i * 4;
      for (let j = 0; j < numComponents; ++j) {
        expandedData[dstOff + j] = data[srcOff + j];
      }
    }

    // Create the actual texture.
    const tex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, tex);
    gl.texImage2D(
        gl.TEXTURE_2D,    // target
        0,                // level of detail
        gl.RGBA,          // internalformat
        width,            // texture width
        height,           // texture height
        0,                // border
        gl.RGBA,          // format
        gl.FLOAT,         // type
        // pixels - this must FILL the texture
        expandedData,
    );

    // No clamping, and no filtering - we want to retrieve exact values
    // from the texture.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);    
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    // Return texture, and its dimensions.
    return [tex, width, height];
  }  

}

export { OpenGL }
