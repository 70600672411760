import * as React from 'react';
import { Source, Layer, useMap } from 'react-map-gl';
import { MercatorCoordinate } from 'mapbox-gl';
import { featureCollection, point } from '@turf/helpers';
import { FeatureCollection } from 'geojson';

import vShaderSource from './IdwVertex.glsl';
import fShaderSource from './IdwFragment.glsl';
import rounded from '../img/rounded.png';
// See: https://github.com/mapbox/mapbox-gl-js/issues/6856#issuecomment-559903043
import { OpenGL } from './OpenGL';
import { Campaign, Measurement, Parameter } from '../../api/models';
import { ResultApi } from '../../api/services/ResultApi';
import { IdwScale } from './IdwScale';

interface IProps {
  campaign: Campaign;
  parameter: Parameter;
}

const IdwLayer = (props: IProps) => {
  const map = useMap();
  const program = React.useRef<WebGLProgram>(null);
  const buffer = React.useRef<WebGLBuffer>(null);
  const texture = React.useRef<WebGLTexture>(null);
  const textureWidth = React.useRef<number>(0);
  const textureHeight = React.useRef<number>(0);
  const [measurements, setMeasurements] = React.useState<Measurement[]>([]);
  const [features, setFeatures] = React.useState<FeatureCollection>(null);
  const [key, setKey] = React.useState<number>(0);
  const [min, setMin] = React.useState<number>(0);
  const [max, setMax] = React.useState<number>(0);

  // Load rounded image into map.
  React.useEffect(() => {
    map.current.loadImage(rounded, (e, img) => {
      if(e) { console.log(e); throw(e); }
      if(!map.current.hasImage('rounded')) {
        map.current.addImage('rounded', img, {
          content: [3, 3, 13, 13],
          stretchX: [[7, 9]],
          stretchY: [[7, 9]]          
        })
      }
    });
  }, []);

  // When campaign or parameter changes, load new data in.
  React.useEffect(() => {
    const controller = new AbortController();
    ResultApi.map(null, props.campaign, props.parameter, controller.signal)
    .then(res => {
      setMeasurements([...res]);
      const minVal = Math.min(...res.map(s => s.v));
      const maxVal = Math.max(...res.map(s => s.v));
      setMin(minVal);
      setMax(maxVal);      
      setFeatures(featureCollection(res.map(m => point([ m.x, m.y ], { v: m.v.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) }))));
      setKey(k => k+1);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    })
    return () => { if(controller) controller.abort(); }
  }, [props.campaign, props.parameter]);

  const addLayer = (map: mapboxgl.Map, gl: WebGLRenderingContext) => {
    // AddLayer is only called once.

    // create a vertex shader
    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vShaderSource);
    gl.compileShader(vertexShader);

    // create a fragment shader
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fShaderSource);
    gl.compileShader(fragmentShader);

    // link the two shaders into a WebGL program
    program.current = gl.createProgram();
    gl.attachShader(program.current, vertexShader);
    gl.attachShader(program.current, fragmentShader);
    gl.linkProgram(program.current);

    // Create buffer from vertices:
    buffer.current = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer.current);
    gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 1.0, 1.0]),
        gl.STATIC_DRAW
    );    

    // Make an OpenGL texture that contains station data (x,y,value).
    // The texture dimensions are saved and will be passed into a shader 
    // uniform. Note that lat/lng is converted to Mercator x,y, which has 
    // a range of [0..1].
    const minVal = Math.min(...measurements.map(s => s.v));
    const maxVal = Math.max(...measurements.map(s => s.v));

    [texture.current, textureWidth.current, textureHeight.current] = 
      OpenGL.makeDataTexture(gl, measurements.map(m => {
        const coord = MercatorCoordinate.fromLngLat({ lng: m.x, lat: m.y });
        return [coord.x, coord.y, (m.v - minVal) / (maxVal - minVal)];
      }).flat(), 3);
  }

  const render = (gl: WebGLRenderingContext, matrix: number[]) => {
    // Render is called many times while the map is panned/zoomed.
    // You cannot have access to the map though.
    gl.useProgram(program.current);
    // Pass a matrix uniform in: 
    gl.uniformMatrix4fv(gl.getUniformLocation(program.current, 'u_matrix'), false, matrix);
    // Pass in number of stations:
    gl.uniform1i(gl.getUniformLocation(program.current, "u_numStations"), measurements.length);
    // put the data texture on texture unit 0
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture.current);
    // Pass data texture in (use texture unit 0):
    gl.uniform1i(gl.getUniformLocation(program.current, "u_dataTexture"), 0);
    // Pass texture size in:
    // Tell the shader the size of the position texture
    gl.uniform2f(gl.getUniformLocation(program.current, "u_dataTextureSize"), textureWidth.current, textureHeight.current);

    const aPos = gl.getAttribLocation(program.current, 'a_pos');
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer.current);
    gl.enableVertexAttribArray(aPos);
    gl.vertexAttribPointer(aPos, 2, gl.FLOAT, false, 0, 0);
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  }  

  return (
    <>
      {/* We use a changing key to make sure the Layer calls AddLayer when the data changes. */}
      {/* @ts-ignore */}
      <Layer key={key} id="triangulationlayer" beforeId="overlay" type={"custom" as any} onAdd={addLayer} render={render}/>
      <Source type="geojson" data={features}>
        <Layer 
          id="valueslayer" type="symbol"
          minzoom={10}
          layout={{
            //"icon-image": "rounded",
            //"icon-text-fit": "both",
            // "icon-text-fit-padding": [0, 2, 0, 2],
            "text-field": ["get", "v"],
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 12,
            'text-anchor': 'left',
            'text-offset': [1.5, 0],
          }}
          paint={{
            'text-color': '#fff',
            'text-opacity': 1,
            'text-halo-width': 1.5,
            'text-halo-color': '#333',
            'text-halo-blur': 0

          }}
        />
      </Source>
      <IdwScale min={min} max={max}/>
    </>
  );
}

export { IdwLayer }
