import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Button } from '@independent-software/typeui/controls';

import { Drawer } from '../../../ui/containers/panels/Drawer';

interface IProps {
  /** @ignore */
  className?: string;
  open?: boolean;
}

const UserTray = (props: IProps) => {
  if(!props.open) return null;

  const handleSignout = () => {
    /* axios.get(`${App.config.apiURL}signout?access_token=${auth.access_token}`)
    .then(_ => {
      navigate("/");
      auth.signout();
    })
    .catch(error => {
      ToastService.toast("Signout failed.");
    }); */    
  }    

  const handleAccount = () => {
    //navigate("/account");
  }

  return (
    <Drawer.Tray>
      <Drawer.Header>
        (Not yet implemented)
      </Drawer.Header>
      <Body>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button negative onClick={handleSignout}>Sign out</Button>
          <Button onClick={handleAccount}>Manage account</Button>
        </div>
      </Body>
    </Drawer.Tray>
  );
}

const Body = styled.div`
  margin: 16px;
`

export { UserTray }
