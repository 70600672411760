import * as React from 'react';

interface IXhr {
  /**
   * Calls an async function that returns a promise. If the promise fails, 
   * an Xhr dialog is shown. Optionally, a second function can be provided that
   * is called when the async function terminates (success or fail).
   * 
   * @param f Async function to call
   * @param final Optional finally function
   */  
  call: (f: () => Promise<any>, final?: () => void) => Promise<any>;
}

const XhrContext = React.createContext<IXhr>({
  call: () => null
});

export { XhrContext }
