import * as React from 'react';

import { Icon, Input } from '@independent-software/typeui/controls';
import { Number } from '@independent-software/typeui/formatters/Number';

import { System } from '../../../api/models';
import { Table } from '../../../ui/containers/lists/Table';
import { Highlight } from '../../../ui/core/text/Highlight';
import { SystemInfoBox } from '../../infoboxes/SystemInfoBox';
import { Colors } from '../../../theme/Colors';
import { Drawer } from '../../../ui/containers/panels/Drawer';
import { useFilter } from '../../../contexts/filter/useFilter';
import { Loader } from '../../../ui/core';

interface IProps {
  /**
   * Must the panel show a loading state?
   */
  loading: boolean;
  /** 
   * Is the panel currently expanded? 
   */
  expanded: boolean;
  /**
   * Is this panel currently hidden by other panels? Then the InfoBox must
   * not show.
   */
  hidden: boolean;
  /**
   * Selected system
   */
  system: System;
  /**
   * Available systems
   */
  systems: System[];
  /**
   * Toggles Tray expand state
   */
  onToggle: () => void;
  /**
   * Fired when a System is selected.
   */
  onSelect: (system: System) => void;
  /**
   * Fired when a System is viewed.
   */
  onView: (system: System) => void;
}

const SystemsList = (props: IProps) => {
  const filter = useFilter();
  const [sort, setSort] = React.useState("name");
  const [reverse, setReverse] = React.useState(false);
  const [q, setQ] = React.useState(null);

  const handleSort = (sort: string, reverse: boolean) => {
    setSort(sort);
    setReverse(reverse);
  }

  const handleChangeQ = (value: string) => setQ(value);

  const lowerQ = (q ?? "").toLowerCase();
  const filteredSystems = Table.sort(props.systems, sort, reverse)
    .filter(system => system.name.toLowerCase().includes(lowerQ));

  return (
    <Drawer.Tray expandable expanded={props.expanded} onToggle={props.onToggle}>
      {props.loading && <Loader/>}
      <Drawer.Options white>
        <Input fluid transparent clearable type="text" placeholder="Search" value={q} onChange={handleChangeQ}>
          <Icon name="search"/>
        </Input>
      </Drawer.Options>

      <Drawer.Header justify>
        SYSTEMS
      </Drawer.Header>

      <Table 
        animated hover data={filteredSystems} active={props.system} sort={sort} reverse={reverse} onSort={handleSort}
        singular="system" plural="systems" noCounter={props.loading} onClick={s => props.onSelect(s)} 
        noData={<div style={{padding: '0 16px'}}><Table.NoData/></div>}
        infoBox={props.hidden ? null : <SystemInfoBox systems={[props.system]} active={filter.hasSystem(props.system)} onToggle={filter.toggleSystem} onView={props.onView}/>}
      >
        <Table.Column band={(item: System) => filter.hasSystem(item) ? Colors.MODERATE : "transparent" } name="Name" width={3} sort="name">{(item: System) => item.name ? <Highlight value={item.name} q={q}/>: <>(no name)</>}</Table.Column>
        <Table.Column name="Stations" width={1} align='right' sort="stations_count">{(item: System) => <Number value={item.stations_count} decimals={0}/>}</Table.Column>
        {props.expanded && <Table.Column name="Campaigns" width={1} align='right' sort="campaigns_count">{(item: System) => <Number value={item.campaigns_count} decimals={0}/>}</Table.Column>}
      </Table>
    </Drawer.Tray>
  );
}

export { SystemsList }
