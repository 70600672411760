import * as React from 'react';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

import { ITableColumnProps } from './TableColumn';
import { TableHeaderCell } from './TableHeaderCell';
import { AddColumnButton } from './buttons/AddColumnButton';
import { FilterButton } from './buttons/FilterButton';

interface IProps {
  /** @ignore */
  children?: React.ReactElement<ITableColumnProps> | React.ReactElement<ITableColumnProps>[];
  /** Current sort key. */
  sort?: string;
  /** Current sort direction. */
  reverse: boolean;
  /** Fired when a header cell is clicked. */
  onClick: (sort: string, reverse: boolean) => void;
  /** If present, a check column is added. */
  onCheck?: (index: number) => void;
  /** If present, a column deletion option is added. */
  onDeleteColumn?: (index: number) => void;  
  /** If present, an add column option is added. */
  onAddColumn?: () => void;  
  /** If present, a filter option is added. */
  filter?: React.ReactNode;
}

/** 
 * The TableHeader takes a a set of TableColumn children,
 * and uses these to format each header cell.
 */
const TableHeader = (props: IProps) => {
  // Calculate total weight of weighted children, so that each child column can 
  // have a percentage width. Children width a fixed (string) width are 
  // ignored.
  let totalWeight = 0;
  React.Children.forEach(props.children, (child) => {
    if(child != null) {
      // Width defaults to 1.
      const width = child.props.width ?? 1;
      // If width is numeric, then add it to totalWeight.
      if (typeof width === 'number' && !isNaN(width)) totalWeight += width;
    }
  });

  return (
    <thead>
      <tr>
        {props.onCheck && 
          <th style={{width: "48px"}}>
            <Checkbox type="check" onChange={() => props.onCheck(null)}/>
          </th>
        }
        {React.Children.map(props.children, (child, index) => {
          if(child == null) return null;
          // Column width defaults to 1.
          const width = child.props.width ?? 1; 
          // String widths are used verbatim:
          let strWidth: string = width.toString();
          // Weighted width is a percentage of total weight:
          if (typeof width === 'number' && !isNaN(width)) {
            strWidth = (width * 100 / totalWeight) + "%";
          }
          return (
            <TableHeaderCell 
              key={index}
              width={strWidth}
              active={props.sort && props.sort === child.props.sort}
              sortable={child.props.sort != null}
              reverse={props.sort === child.props.sort ? props.reverse : child.props.reverse}
              onClick={(child.props.sort && props.onClick) ? () => props.onClick(child.props.sort, child.props.reverse) : null}
              onDeleteColumn={props.onDeleteColumn ? () => props.onDeleteColumn(index) : null}
            >
              {child.props.name}
            </TableHeaderCell>
          );
        })}
        {props.onAddColumn && 
          <th style={{width: "48px"}}>
            <AddColumnButton onClick={props.onAddColumn}/>
          </th>
         }
         {props.filter &&
          <th style={{width: "48px"}}>
            <FilterButton filter={props.filter}/>
          </th>         
         }
      </tr>
    </thead>
  );
}

export { TableHeader } 
