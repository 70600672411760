import styled, { css } from '@independent-software/typeui/styles/Theme';
import * as React from 'react';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Font size */
  size: 'h1' | 'h2';
}

const HeadingBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const HeadingStyled = styled(HeadingBase)`
  font-size: ${p => {
    switch(p.size) {
      case 'h1': return 36;
      default: return 16;
    }
  }}px;
  padding: ${p => {
    switch(p.size) {
      case 'h1': return '16px 0';
      default: return '8px 0';
    }    
  }};
  ${p => p.size == 'h2' && css`text-transform: uppercase;`}
  font-weight: 700;
  color: #fff;
  user-select: none;

  // Use flex to vertically center items:
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; // Shame this doesn't work on a container with no fixed width.
`

/**
 * Headings exist to bring consistency to font sizes across AquaRisk.
 * Headings can have size `h1` or `h2`. Headings fill the available horizontal
 * space, clipping overflowing text. Unfortunately `text-overflow` does not 
 * work, as these headings allow ReactNode content and have no fixed width.
 */
const Heading = (props: IProps) => <HeadingStyled {...props}/>;

export { Heading }
