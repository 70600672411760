import * as React from 'react';
import { ToastService } from '@independent-software/typeui/controls';

import { SystemsList } from './SystemsList';
import { System } from '../../../api/models';
import { ViewSystem } from './ViewSystem';
import { SystemApi } from '../../../api/services/SystemApi';
import { useAuth } from '../../../contexts/auth/useAuth';
import { EditSystem } from './EditSystem';
import { useXhr } from '../../../contexts/xhr/useXhr';

type TMode = 'view' | 'edit';

interface IProps {
  open?: boolean;
}

const SystemsManager = (props: IProps) => {
  const auth = useAuth();
  const xhr = useXhr();
  const [system, setSystem] = React.useState<System>(null);
  const [systems, setSystems] = React.useState<System[]>([]);
  const [mode, setMode] = React.useState<TMode>(null);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  //
  // Load systems on mount.
  // 
  React.useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    SystemApi.list(auth.access_token, controller.signal)
    .then(res => {
      setSystems(res.data);
    })
    .catch((error) => {
      // Cancellation is normal; be quiet.
      if(!controller.signal.aborted) throw error;
    })
    .finally(() => setLoading(false));
    return () => { if(controller) controller.abort(); }    
  }, []);

  const handleSelect = (system: System) => setSystem(system);

  const handleView = (system: System) => {
    setLoading(true);
    xhr.call(() => {
      return SystemApi.get(system.id, auth.access_token)
      .then((res) => {
        setExpanded(false);
        setMode('view');
        setSystem(res);        
      })
      .finally(() => setLoading(false));
    })
  }

  const handleEdit = (system: System) => {
    setLoading(true);
    xhr.call(() => {
      return SystemApi.update(system.id, system, auth.access_token)
        .then((res: System) => {
          setSystems([...systems.filter(s => s.id != system.id), system]);
          setMode(null);
          ToastService.toast("System updated.");
        })
        .finally(() => setLoading(false));
      })
  }

  if(!props.open) return null;

  return (
    <>
      <SystemsList hidden={mode != null} loading={loading} system={system} systems={systems} onSelect={handleSelect} onView={handleView} expanded={expanded} onToggle={() => setExpanded(!expanded)}/>
      {mode == 'view' && <ViewSystem loading={loading} system={system} onClose={() => setMode(null)} onEdit={() => setMode('edit')}/>}
      {mode == 'edit' && <EditSystem loading={loading} system={system} onClose={() => setMode('view')} onEdit={handleEdit}/>}
    </>
  );
}

export { SystemsManager }
