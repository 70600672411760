import * as React from 'react';
import { Source, Layer, useMap } from 'react-map-gl';
import * as turf from '@turf/helpers';

import { Station } from '../api/models';
import { Colors } from '../theme/Colors';
import imgStationDefault from './img/station_default.png';
import imgStationDefaultHover from './img/station_default_hover.png';
import imgStationSelected from './img/station_selected.png';
import imgStationSelectedHover from './img/station_selected_hover.png';

interface IProps {
  stations: Station[],
  selectedStation: Station,
  hoveredStation: Station
}

const MARKER_FILL_COLOR = "#ffffff";
const MARKER_STROKE_COLOR = "#ffffff";
const OPAQUE_ZOOM = 11;
const TRANSPARENT_ZOOM = 8;

const StationMarkers = (props: IProps) => {
  const map = useMap();

  const loadImage = (img: string, name: string) => {
    map.current.loadImage(img, (e, img) => {
      if(e) { console.log(e); throw(e); }
      if(!map.current.hasImage(name)) {
        map.current.addImage(name, img)
      }
    })
  }

  React.useEffect(() => {
    loadImage(imgStationDefault, 'imgStationDefault');
    loadImage(imgStationDefaultHover, 'imgStationDefaultHover');
    loadImage(imgStationSelected, 'imgStationSelected');
    loadImage(imgStationSelectedHover, 'imgStationSelectedHover');
  }, []);

  const stationFeatures = React.useMemo(() => {
    return turf.featureCollection(
      props.stations
        .filter(s => s.latitude != null && s.longitude != null)
        .map(s => turf.point([s.longitude, s.latitude], { id: s.id }))
    );   
  }, [props.stations]);

  const markersShadows = () => 
    <Layer 
      id="markers-shadows"
      minzoom={TRANSPARENT_ZOOM}
      type="circle"
      paint={{
        "circle-radius": 15,
        "circle-blur": 0.5,
        "circle-color": '#000',
        "circle-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
      }}  
    />

  const markersCircles = () => 
    <Layer
      id="markers-circles"
      minzoom={TRANSPARENT_ZOOM}
      filter={['!=', ['get', 'id'], props.selectedStation ? props.selectedStation.id : null ]}
      type="circle"
      paint={{
        "circle-radius": 6,
        "circle-color": MARKER_FILL_COLOR,
        "circle-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
        "circle-stroke-color": "#333",
        "circle-stroke-width": 2,
        "circle-stroke-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
      }}
    />

  const markerOuterCircles = () =>
    <Layer
      id="markers-outer-circles"
      minzoom={TRANSPARENT_ZOOM}
      filter={['!=', ['get', 'id'], props.selectedStation ? props.selectedStation.id : null ]}
      type="circle"
      paint={{
        "circle-radius": 12,
        "circle-color": MARKER_FILL_COLOR,
        "circle-stroke-color": "#333",
        "circle-stroke-width": 2,
        "circle-stroke-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
      }}
    />
  

  const markersCirclesSelected = () =>   
    <Layer
      id="markers-circles-selected"
      minzoom={TRANSPARENT_ZOOM}
      filter={['==', ['get', 'id'], props.selectedStation ? props.selectedStation.id : null ]}
      type="circle"
      paint={{
        "circle-radius": 7,
        "circle-color": Colors.MODERATELY_LOW,
        "circle-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
      }}
    />

  const stroke = () => 
    <Layer
      id="markers-stroke-hover"
      minzoom={TRANSPARENT_ZOOM}
      filter={['!=', ['get', 'id'], props.hoveredStation ? props.hoveredStation.id : null ]}
      type="circle"
      paint={{
        "circle-radius": 8,
        "circle-opacity": 0,
        "circle-color": "transparent",
        "circle-stroke-color": MARKER_STROKE_COLOR,
        "circle-stroke-width": 3,
        "circle-stroke-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 0.5],
      }}
    />

  const strokeHover = () =>
    <Layer
      id="markers-stroke"
      minzoom={TRANSPARENT_ZOOM}
      filter={['==', ['get', 'id'], props.hoveredStation ? props.hoveredStation.id : null ]}
      type="circle"
      paint={{
        "circle-radius": 8,
        "circle-opacity": 0,
        "circle-color": "transparent",
        "circle-stroke-color": Colors.MODERATELY_LOW,
        "circle-stroke-width": 3,
        "circle-stroke-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
      }}
    />

  const stationIcons = () =>
    <Layer id="stations" type="symbol"
      minzoom={TRANSPARENT_ZOOM}
      layout={{
        "icon-image": ["case", 
          ["all", ["==", ["get", "id"], props.selectedStation ? props.selectedStation.id : null], ["==", ["get", "id"], props.hoveredStation ? props.hoveredStation.id : null]], "imgStationSelectedHover", 
          ["==", ["get", "id"], props.selectedStation ? props.selectedStation.id : null], "imgStationSelected", 
          ["==", ["get", "id"], props.hoveredStation ? props.hoveredStation.id : null], "imgStationDefaultHover",
          "imgStationDefault"
        ], 
        "icon-allow-overlap": true,
        "icon-size": 1,
      }}
      paint={{
        "icon-opacity": ["interpolate", ["linear"], ["zoom"], TRANSPARENT_ZOOM, 0, OPAQUE_ZOOM, 1],
      }}
    />    

  return (
    <Source id="stations" type="geojson" data={stationFeatures}>
      {markersShadows()}
      {markerOuterCircles()}
      {markersCircles()}
      {/* {stationIcons()} */}
      {markersCirclesSelected()}
      {stroke()}
      {strokeHover()}
    </Source>
  )

}

export { StationMarkers }
